import React, { Component } from "react";
import { Row, Col } from "antd";
import { loader } from "../utils/loadingIcon";
import { isAdmin } from "../utils/permissions";
import Loadable from "react-loadable";

import UsersAndRolesPage from "../pages/UsersAndRolesPage";
// import MembersSchema from "../settings/membersSchema";
// import LifeMemberNo from "../settings/lifeMemberNo";

const SETTINGS = [
  { label: "Users & Roles", hasPermission: isAdmin },
  { label: "Members Schema", hasPermission: () => true },
  { label: "Life Member No", hasPermission: () => true }
];

const MembersSchema = Loadable({
  loader: () => import("../settings/membersSchema"),
  loading: loader
});

const LifeMemberNo = Loadable({
  loader: () => import("../settings/lifeMemberNo"),
  loading: loader
});

class SettingsPage extends Component {
  constructor() {
    super();
    this.state = {
      selectedItem: "Users & Roles"
    };
  }

  getComponent = () => {
    const { selectedItem } = this.state;

    switch (selectedItem) {
      case "Users & Roles":
        return <UsersAndRolesPage />;
      case "Members Schema":
        return <MembersSchema />;
      case "Life Member No":
        return <LifeMemberNo />;
      default:
        return <div />;
    }
  };

  render() {
    const { selectedItem } = this.state;

    return (
      <Row gutter={6}>
        <Col span={4}>
          {SETTINGS.map((item, index) => (
            <div
              key={index}
              className="table-highlight"
              style={{
                padding: "8px 8px",
                cursor: "pointer",
                background:
                  (item.label === selectedItem && "#3949AB") || "#FFFFFF",
                borderRadius: 4,
                marginBottom: 4,
                fontSize: 15,
                fontWeight: 600,
                color: item.label === selectedItem && "#FFFFFF"
              }}
              onClick={() => this.setState({ selectedItem: item.label })}
            >
              {item.hasPermission() && item.label}
            </div>
          ))}
        </Col>
        <Col span={20}>
          <div
            style={{
              background: "#FFFFFF",
              padding: 16,
              borderRadius: 4,
              height: `calc(100vh - 48px)`,
              overflowY: "auto"
            }}
          >
            {this.getComponent()}
          </div>
        </Col>
      </Row>
    );
  }
}

export default SettingsPage;
