import axios from "axios";

let baseURL = "http://localhost:8080";

if (process.env.NODE_ENV === "production") {
  baseURL = "https://members.marisstellaoba.com/api";
}

export async function sendRequest(url, method = "GET", data = {}) {
  const userToken = localStorage.getItem("userToken");
  return axios({
    url,
    data,
    method,
    baseURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken
    }
  });
}
