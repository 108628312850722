import { createSelector } from "reselect";

export const isLoadingRequests = createSelector(
  state => state.requests.isLoading,
  isLoading => isLoading
);

export const requestsSelector = createSelector(
  state => state.requests.requests,
  requests => selectRequests(requests)
);

export const pendingRequestsSelector = createSelector(
  state => state.requests.requests,
  requests => selectPendingRequests(requests)
);

function selectRequests(requests) {
  return requests.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
  );
}

function selectPendingRequests(requests) {
  let pending = requests.filter(t => t.status === "pending");
  return pending;
}
