import * as types from "./types";

export function fetchReports() {
  return {
    type: types.FETCH_REPORTS
  };
}

export function createReport(payload) {
  return {
    type: types.CREATE_REPORT,
    payload
  };
}

export function updateReport(payload) {
  return {
    type: types.UPDATE_REPORT,
    payload
  };
}

export function deleteReport(payload) {
  return {
    type: types.DELETE_REPORT,
    payload
  };
}
