import { createSelector } from "reselect";

export const isLoadingRoles = createSelector(
  state => state.roles.isLoading,
  isLoading => isLoading
);

export const rolesSelector = createSelector(
  state => state.roles.roles,
  roles => roles
);
