import React, { Component } from "react";
import { Icon, Button, Alert } from "antd";
import { reduxForm, Field } from "redux-form";
import { Input } from "../../utils/formComponents";

class Login extends Component {
  onKeyPress = event => {
    if (event.key === "Enter") {
      this.props.handleSubmit();
    }
  };

  render() {
    const { handleSubmit, loginError } = this.props;
    return (
      <form onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
        {loginError && (
          <div style={{ marginBottom: 16 }}>
            <Alert message="Invalid username or password" type="error" />
          </div>
        )}
        <div style={{ marginTop: 16 }}>
          <Field
            name="username"
            component={Input}
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Username"
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <Field
            name="password"
            component={Input}
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Password"
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={handleSubmit}
          >
            Login
          </Button>
        </div>
      </form>
    );
  }
}

Login = reduxForm({
  form: "login-form"
})(Login);

export default Login;
