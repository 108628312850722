import React from "react";
import { Input } from "antd";

const { TextArea } = Input;

export const renderInput = props => {
  const {
    meta: { touched, error, warning }
  } = props;
  return (
    <span>
      <Input {...props} {...props.input} />
      {touched &&
        ((error && <div style={{ color: "#cf1322" }}>{error}</div>) ||
          (warning && <div style={{ color: "#ffa940" }}>{warning}</div>))}
    </span>
  );
};

export const renderTextArea = props => {
  const {
    meta: { touched, error, warning }
  } = props;
  return (
    <span>
      <TextArea {...props} {...props.input} />
      {touched &&
        ((error && <div style={{ color: "#cf1322" }}>{error}</div>) ||
          (warning && <div style={{ color: "#ffa940" }}>{warning}</div>))}
    </span>
  );
};
