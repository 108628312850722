import React, { Component } from "react";
import { Drawer } from "antd";
import SendEmailForm from "./SendEmailForm";
import { connect } from "react-redux";
import { sendEmail } from "../../redux/actions";

class SendEmail extends Component {
  handleSubmit = values => {
    this.props.sendEmail(values);
  };

  render() {
    return (
      <Drawer
        visible={this.props.visible}
        onClose={this.props.onClose}
        placement="right"
        width="80vw"
        {...this.props}
      >
        <SendEmailForm onSubmit={this.handleSubmit} {...this.props} />
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  sendEmail
};

export default connect(
  null,
  mapDispatchToProps
)(SendEmail);
