import React, { Component, Fragment } from "react";
import {
  Button,
  Divider,
  Icon,
  Spin,
  Menu,
  Dropdown,
  Modal,
  Result
} from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  isLoadingMembers,
  membersSelector,
  membersSchemaSelector
} from "../redux/selectors";
import { Table } from "../components/table";
import moment from "moment";
import FilterForm from "../forms/filter";
import Report from "../forms/report";
import { ExportToCsv } from "export-to-csv";
import SendEmail from "../forms/sendEmail";
import Print from "../forms/print";

import { loadingIcon } from "../utils/loadingIcon";

class ReportGeneral extends Component {
  constructor() {
    super();
    this.state = {
      columns: [],
      searchQuery: "",
      selectedItems: [],
      filters: [],
      isFilterModalVisible: false,
      isReportVisible: false,
      isSendEmailVisible: false,
      isPrintVisible: false,
      report: undefined
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const savedFilters =
      location.state && location.state.report && location.state.report.filters;
    if (savedFilters !== undefined && savedFilters.length > 0) {
      this.setState({ filters: savedFilters });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.schema &&
      (prevProps.schema !== this.props.schema ||
        this.state.columns.length === 0)
    ) {
      let columns = this.props.schema.map(t => ({
        title: t.name,
        key: t.name,
        render:
          (t.type === "Date" && (t => moment(t).format("DD MMM YYYY"))) ||
          (t => t)
      }));
      this.setState({ columns });
    }
  }

  exportData = () => {
    let members = Object.assign([], this.state.selectedItems);
    const { columns } = this.state;

    // map data before exporting
    let data = members.map(t => {
      let { _id, createdAt, updatedAt, __v, ...rest } = t;
      for (let column of columns) {
        if (!rest[column.key]) rest[column.key] = "";
      }
      return rest;
    });

    const options = {
      filename: "members",
      showLabels: true,
      useKeysAsHeaders: true
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  getDataSource = () => {
    const { members } = this.props;
    const { filters } = this.state;

    let result = (filters.length > 0 && Object.assign([], members)) || [];

    filters.forEach(filter => {
      let regex;
      let tempResult;

      if (filter.logic && filter.logic === "OR") {
        tempResult = Object.assign([], result);
        result = (filters.length > 0 && Object.assign([], members)) || [];
      }

      if (filter.comparator === "Contains") {
        regex = new RegExp(
          `${filter.value
            .toString()
            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}`,
          "i"
        );

        result = result.filter(
          t => t[filter.key] && t[filter.key].search(regex) >= 0
        );
      } else if (filter.comparator === "Starts With") {
        regex = new RegExp(
          `^${filter.value
            .toString()
            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}`,
          "i"
        );

        result = result.filter(
          t => t[filter.key] && t[filter.key].search(regex) >= 0
        );
      } else if (filter.comparator === "Is Before") {
        result = result.filter(
          t => t[filter.key] && moment(t[filter.key]).isBefore(filter.value)
        );
      } else if (filter.comparator === "Is After") {
        result = result.filter(
          t => t[filter.key] && moment(t[filter.key]).isAfter(filter.value)
        );
      } else if (filter.comparator === "Is Between") {
        result = result.filter(
          t =>
            t[filter.key] &&
            moment(t[filter.key]).isBetween(filter.value, filter.value2)
        );
      }

      if (filter.logic && filter.logic === "OR") {
        result = [...tempResult, ...result];
      }
    });

    return result;
  };

  onFilter = values => {
    this.setState({ ...values, isFilterModalVisible: false });
  };

  onFilterModal = () => {
    this.setState({ isFilterModalVisible: true });
  };

  onReport = () => {
    const { location } = this.props;
    const { filters } = this.state;

    const newItem = location.state && location.state.newItem;
    const report = location.state && location.state.report;

    const initialReportValues = (newItem && { filters }) || {
      _id: report._id,
      name: report.name,
      filters
    };

    this.setState({
      initialReportValues,
      isReportVisible: true
    });
  };

  onCloseReport = () => {
    this.setState({ isReportVisible: false });
  };

  onReportSubmitSuccess = () => {
    this.setState({ isReportVisible: false });
  };

  onSendEmail = () => {
    let members = Object.assign([], this.state.selectedItems);
    let emails = members.map(t => t.Email);
    emails = emails.filter(t => t !== undefined && t !== "");
    this.setState({
      initialSendEmailValues: { to: emails },
      isSendEmailVisible: true
    });
  };

  onCloseSendEmail = () => {
    this.setState({ isSendEmailVisible: false });
  };

  onSendEmailSubmitSuccess = () => {
    this.setState({ isSendEmailVisible: false, selectedItems: [] });
  };

  onPrintToPdf = () => {
    let members = Object.assign([], this.state.selectedItems);
    this.setState({ initialPrintValues: members, isPrintVisible: true });
  };

  onClosePrint = () => {
    this.setState({ isPrintVisible: false });
  };

  onPrintSubmitSuccess = () => {
    this.setState({ isPrintVisible: false });
  };

  onExportToText = () => {
    let members = Object.assign([], this.state.selectedItems);
    let result = "";
    members.forEach(member => {
      result +=
        member["LM No"] +
        "\n" +
        member["Title"] +
        ". " +
        member["Print Name"] +
        " " +
        member["Surname"] +
        "\n" +
        member["Address 1"] +
        "\n" +
        member["Address 2"] +
        "\n" +
        member["City"] +
        "\n" +
        member["State"] +
        " " +
        member["Postal Code"] +
        "\n\n";
    });

    const element = document.createElement("a");
    const file = new Blob([result], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "output.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  onSelectChange = (checked, item) => {
    const { selectedItems } = this.state;
    if (checked) {
      let items = Object.assign([], selectedItems);
      if (item instanceof Array) {
        items = item;
      } else {
        items.push(item);
      }
      this.setState({ selectedItems: items });
    } else {
      if (item instanceof Array) {
        this.setState({ selectedItems: [] });
      } else {
        let items = selectedItems.filter(t => t._id !== item._id);
        this.setState({ selectedItems: items });
      }
    }
  };

  handelActionClick({ key }) {
    switch (key) {
      case "sendEmail":
        this.onSendEmail();
        break;
      case "exportToCsv":
        this.exportData();
        break;
      case "printToPdf":
        this.onPrintToPdf();
        break;
      case "exportToText":
        this.onExportToText();
        break;
      default:
        return;
    }
  }

  render() {
    const dataSource = this.getDataSource();
    const { isLoading, location } = this.props;
    const { columns, filters, selectedItems } = this.state;

    const reportName =
      location.state && location.state.report && location.state.report.name;
    const newItem = location.state && location.state.newItem;

    const Actions = (
      <Menu onClick={e => this.handelActionClick(e)}>
        <Menu.Item key="sendEmail">Send Email</Menu.Item>
        <Menu.Item key="exportToCsv">Export To CSV</Menu.Item>
        <Menu.Item key="printToPdf">Print To PDF</Menu.Item>
        <Menu.Item key="exportToText">Export To Text</Menu.Item>
      </Menu>
    );

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 16 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <Button
              icon="arrow-left"
              onClick={() => this.props.history.goBack()}
            />
            <Divider type="vertical" />
            <span style={{ fontWeight: 400, fontSize: 20 }}>Report</span>
            <Divider type="vertical" />
            <span style={{ fontWeight: 600, fontSize: 20 }}>{reportName}</span>

            {filters.length > 0 && (
              <Fragment>
                <Divider type="vertical" />
                <Button icon="save" onClick={this.onReport}>
                  {(newItem && "Save") || "Update"}
                </Button>
              </Fragment>
            )}
          </div>

          <div style={{ flex: 1, textAlign: "right" }}>
            <Button icon="filter" onClick={this.onFilterModal}>
              Filter
            </Button>
            <Divider type="vertical" />
            <Dropdown
              overlay={Actions}
              trigger={["click"]}
              disabled={selectedItems.length === 0}
            >
              <Button style={{ marginLeft: 8 }}>
                Actions
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </div>

        {filters.length > 0 && (
          <div>
            <span className="bold-text">Filters: </span>
            {filters.map((item, index) => (
              <Fragment key={index}>
                <span className="bold-text">{` ${item.logic || ""} `}</span>
                <span>{` ${item.key || ""} `}</span>
                <span className="bold-text">{` ${item.comparator ||
                  ""} `}</span>
                <span>{` "${item.value || ""}" `}</span>
              </Fragment>
            ))}
          </div>
        )}

        {filters.length > 0 && (
          <div style={{ marginBottom: 8, fontWeight: 400, textAlign: "right" }}>
            <span>{`${selectedItems.length} / ${dataSource.length} selected`}</span>
          </div>
        )}

        {(filters.length > 0 && (
          <Table
            columns={columns}
            dataSource={dataSource}
            selectable
            onSelectChange={this.onSelectChange}
            selectedItems={selectedItems}
            pagination
          />
        )) || (
          <Result
            icon={<Icon type="warning" theme="filled" />}
            title="Please select filters and run report"
            extra={
              <Button icon="filter" onClick={this.onFilterModal}>
                Filter
              </Button>
            }
          />
        )}

        <Modal
          width={960}
          footer={null}
          visible={this.state.isFilterModalVisible}
          onCancel={() => this.setState({ isFilterModalVisible: false })}
        >
          <FilterForm
            onSubmit={this.onFilter}
            initialValues={{ filters: this.state.filters }}
          />
        </Modal>

        <Report
          visible={this.state.isReportVisible}
          onClose={this.onCloseReport}
          onSubmitSuccess={this.onReportSubmitSuccess}
          initialValues={this.state.initialReportValues}
          enableReinitialize
          newItem={newItem}
        />

        <SendEmail
          visible={this.state.isSendEmailVisible}
          onClose={this.onCloseSendEmail}
          onSubmitSuccess={this.onSendEmailSubmitSuccess}
          initialValues={this.state.initialSendEmailValues}
          enableReinitialize
        />

        <Print
          visible={this.state.isPrintVisible}
          onClose={this.onClosePrint}
          onSubmitSuccess={this.onPrintSubmitSuccess}
          initialValues={this.state.initialPrintValues}
          enableReinitialize
        />
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMembers,
  membersSelector,
  membersSchemaSelector,
  (isLoading, members, schema) => ({
    isLoading,
    members,
    schema
  })
);

export default connect(mapStateToProps)(ReportGeneral);
