import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* fetchRequests() {
  try {
    const requests = yield call(sendRequest, "requests", "GET");
    yield put({ type: types.FETCH_REQUESTS_SUCCESS, payload: requests.data });
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* createRequest({ payload }) {
  try {
    const response = yield call(sendRequest, "requests", "POST", payload);
    yield put({ type: types.FETCH_REQUESTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* updateRequest({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `requests/${payload._id}`,
      "PUT",
      payload
    );
    yield put({ type: types.FETCH_REQUESTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* deleteRequest({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `requests/${payload._id}`,
      "DELETE",
      payload
    );
    yield put({ type: types.FETCH_REQUESTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* deleteManyRequests({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      "requests/delete-many",
      "POST",
      payload
    );
    yield put({ type: types.FETCH_REQUESTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* approveRequest({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `requests/approve`,
      "POST",
      payload
    );
    yield put({ type: types.FETCH_REQUESTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* rejectRequest({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `requests/reject`,
      "POST",
      payload
    );
    yield put({ type: types.FETCH_REQUESTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* requestsSagaWatcher() {
  yield takeLatest(types.FETCH_REQUESTS, fetchRequests);
  yield takeEvery(types.CREATE_REQUEST, createRequest);
  yield takeEvery(types.UPDATE_REQUEST, updateRequest);
  yield takeEvery(types.DELETE_REQUEST, deleteRequest);
  yield takeEvery(types.DELETE_MANY_REQUESTS, deleteManyRequests);
  yield takeEvery(types.APPROVE_REQUEST, approveRequest);
  yield takeEvery(types.REJECT_REQUEST, rejectRequest);
}
