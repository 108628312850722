import React, { Component } from "react";
import { Modal, Button, Divider, Tag } from "antd";
import { connect } from "react-redux";
import { approveRequest } from "../redux/actions";
import RequestForm from "../forms/request";

class ViewRequest extends Component {
  onSubmit = values => {
    const { createdAt, updatedAt, __v, status, ...rest } = values;
    this.props.approveRequest(rest);
  };

  render() {
    const status = this.props.initialValues && this.props.initialValues.status;
    return (
      <Modal
        visible={this.props.visible}
        onOk={this.props.onClose}
        onCancel={this.props.onClose}
        width="88vw"
        centered
        closable={false}
        footer={null}
      >
        <div style={{ maxHeight: "88vh" }}>
          <div style={{ display: "flex", marginBottom: 4 }}>
            <div style={{ flex: 1, textAlign: "left" }}>
              <span style={{ fontWeight: 400, fontSize: 24 }}>
                Member Details Update Request
              </span>
              <Divider type="vertical" />
              {(status === "approved" && <Tag color="#389e0d">APPROVED</Tag>) ||
                (status === "rejected" && (
                  <Tag color="#f5222d">REJECTED</Tag>
                )) ||
                (status === "pending" && <Tag color="#faad14">PENDING</Tag>)}
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              {/* <Button
                icon="delete"
                style={{ marginRight: 8 }}
                onClick={() => this.props.onDelete(request)}
              >
                Delete
              </Button> */}
              <Button
                icon="close"
                style={{ marginRight: 8 }}
                onClick={this.props.onClose}
                type="danger"
                shape="circle"
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 24,
              padding: "0px 8px",
              maxHeight: `calc(88vh - 80px)`,
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            <RequestForm
              onSubmit={this.onSubmit}
              onReject={this.onRejectRequest}
              {...this.props}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  approveRequest
};

export default connect(
  null,
  mapDispatchToProps
)(ViewRequest);
