import React, { Component } from "react";
import { Button, Alert } from "antd";
import { reduxForm, Field } from "redux-form";
import { Input } from "../../utils/formComponents";
import { email } from "../../utils/validators";

class ForgotPassword extends Component {
  onKeyPress = event => {
    if (event.key === "Enter") {
      this.props.handleSubmit();
    }
  };

  render() {
    const { handleSubmit, loginError } = this.props;
    return (
      <form
        onSubmit={handleSubmit}
        onKeyPress={this.onKeyPress}
        style={{ width: "75%" }}
      >
        {loginError && (
          <div style={{ marginBottom: 16 }}>
            <Alert message="Invalid username or password" type="error" />
          </div>
        )}
        <div style={{ marginTop: 16 }}>
          <Field
            name="email"
            component={Input}
            placeholder="Email Address"
            validate={[email]}
          />
        </div>

        <div style={{ marginTop: 16 }}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={handleSubmit}
          >
            Reset Password
          </Button>
        </div>
      </form>
    );
  }
}

ForgotPassword = reduxForm({
  form: "forgot-password-form"
})(ForgotPassword);

export default ForgotPassword;
