import { call, put, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifyError } from "../../utils/notification";

function* fetchActivityLogs() {
  try {
    const response = yield call(sendRequest, "activity-log", "GET");
    yield put({
      type: types.FETCH_ACTIVITY_LOG_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* activityLogSagaWatcher() {
  yield takeLatest(types.FETCH_ACTIVITY_LOG, fetchActivityLogs);
}
