import * as types from "../actions/types";

let initialState = {
  isLoading: false,
  reports: []
};

export function reportsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_REPORTS:
      return { ...state, isLoading: true };
    case types.FETCH_REPORTS_SUCCESS:
      return { ...state, reports: payload, isLoading: false };
    case types.CREATE_REPORT_SUCCESS:
      return { ...state, reports: [...state.reports, payload.newItem] };
    case types.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        reports: state.reports.map(item => {
          if (item._id !== payload._id) {
            return item;
          }
          return {
            ...item,
            ...payload.item
          };
        })
      };
    case types.DELETE_REPORT_SUCCESS:
      return {
        ...state,
        reports: state.reports.filter(item => item._id !== payload._id)
      };

    default:
      return state;
  }
}
