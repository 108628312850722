import { call, put, takeEvery } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* sendEmail({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      "actions/send-email",
      "POST",
      payload
    );
    yield put({ type: types.FETCH_REPORTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* actionsSagaWatcher() {
  yield takeEvery(types.ACTION_SEND_EMAIL, sendEmail);
}
