import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* fetchGroups() {
  try {
    const groups = yield call(sendRequest, "groups", "GET");
    yield put({ type: types.FETCH_GROUPS_SUCCESS, payload: groups.data });
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* createGroup({ payload }) {
  try {
    const response = yield call(sendRequest, "groups", "POST", payload);
    yield put({ type: types.FETCH_GROUPS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* updateGroup({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `groups/${payload._id}`,
      "PUT",
      payload
    );
    yield put({ type: types.FETCH_GROUPS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* deleteGroup({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `groups/${payload._id}`,
      "DELETE",
      payload
    );
    yield put({ type: types.FETCH_GROUPS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* groupsSagaWatcher() {
  yield takeLatest(types.FETCH_GROUPS, fetchGroups);
  yield takeEvery(types.CREATE_GROUP, createGroup);
  yield takeEvery(types.UPDATE_GROUP, updateGroup);
  yield takeEvery(types.DELETE_GROUP, deleteGroup);
}
