import { call, put, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* authenticate({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      "users/authenticate",
      "POST",
      payload
    );
    yield put({ type: types.AUTHENTICATE_SUCCESS, payload: response.data });
    yield notifySuccess({ message: "Login Success" });
    localStorage.setItem("userToken", response.data.token);
    localStorage.setItem("userDetails", JSON.stringify(response.data));
    window.location.href = "/";
  } catch (error) {
    yield put({ type: types.AUTHENTICATE_FAILED, payload: null });
    yield notifyError({
      message: "Login Failed",
      description: "Invalid username or password"
    });
  }
}

function* verifyEmail({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      "users/verify-email",
      "POST",
      payload
    );
    yield put({ type: types.VERIFY_EMAIL_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.VERIFY_EMAIL_FAILED, payload: null });
  }
}

function* forgotPassword({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      "users/forgot-password",
      "POST",
      payload
    );
    yield put({ type: types.FORGOT_PASSWORD_SUCCESS, payload: response.data });
    yield notifySuccess(response.data);
  } catch (error) {
    yield put({ type: types.FORGOT_PASSWORD_FAILED, payload: null });
  }
}

export function* authSagaWatcher() {
  yield takeLatest(types.AUTHENTICATE, authenticate);
  yield takeLatest(types.VERIFY_EMAIL, verifyEmail);
  yield takeLatest(types.FORGOT_PASSWORD, forgotPassword);
}
