import React, { Component } from "react";
import { Select, Popover, DatePicker, Icon } from "antd";
import moment from "moment";

const { Option, OptGroup } = Select;

class DateRangePicker extends Component {
  constructor() {
    super();
    this.state = {
      range: {
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month")
      },
      text: "This Month",
      visible: false,
      selectedItem: "thisMonth"
    };
  }

  handleChange = item => {
    const { onSelect } = this.props;

    // defaults to current month
    let range = {
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month")
    };
    let text = "This Month";

    switch (item) {
      case "today":
        range.startDate = moment().startOf("day");
        range.endDate = moment().endOf("day");
        text = "Today";
        break;
      case "thisWeek":
        range.startDate = moment().startOf("isoweek");
        range.endDate = moment().endOf("isoweek");
        text = "This Week";
        break;
      case "thisMonth":
        range.startDate = moment().startOf("month");
        range.endDate = moment().endOf("month");
        text = "This Month";
        break;
      case "thisYear":
        range.startDate = moment().startOf("year");
        range.endDate = moment().endOf("year");
        text = "This Year";
        break;
      case "yesterday":
        range.startDate = moment()
          .subtract(1, "days")
          .startOf("day");
        range.endDate = moment()
          .subtract(1, "days")
          .endOf("day");
        text = "Yesterday";
        break;
      case "previousWeek":
        range.startDate = moment()
          .subtract(1, "weeks")
          .startOf("isoweek");
        range.endDate = moment()
          .subtract(1, "weeks")
          .endOf("isoweek");
        text = "Previous Week";
        break;
      case "previousMonth":
        range.startDate = moment()
          .subtract(1, "months")
          .startOf("month");
        range.endDate = moment()
          .subtract(1, "months")
          .endOf("month");
        text = "Previous Month";
        break;
      case "previousYear":
        range.startDate = moment()
          .subtract(1, "years")
          .startOf("year");
        range.endDate = moment()
          .subtract(1, "years")
          .endOf("year");
        text = "Previous Year";
        break;
      case "custom":
        range = this.state.range;
        text = "Custom";

        break;
      default:
        break;
    }

    this.setState({ range, text, selectedItem: item });
    onSelect && onSelect(range);
  };

  setStartDate = (d, s) => {
    let range = Object.assign({}, this.state.range);
    range.startDate = d;
    this.setState({ range });
    this.setState({ range }, () => {
      this.handleChange("custom");
    });
  };

  setEndDate = (d, s) => {
    let range = Object.assign({}, this.state.range);
    range.endDate = d;
    this.setState({ range }, () => {
      this.handleChange("custom");
    });
  };

  content = () => {
    const {
      range: { startDate, endDate }
    } = this.state;

    return (
      <div>
        <div>
          <Select
            defaultValue="thisYear"
            style={{ width: 180 }}
            onChange={this.handleChange}
            value={this.state.selectedItem}
          >
            <OptGroup key="current" label="Current">
              <Option value="today">Today</Option>
              <Option value="thisWeek">This Week</Option>
              <Option value="thisMonth">This Month</Option>
              <Option value="thisYear">This Year</Option>
            </OptGroup>
            <OptGroup key="previous" label="Previous">
              <Option value="yesterday">Yesterday</Option>
              <Option value="previousWeek">Previous Week</Option>
              <Option value="previousMonth">Previous Month</Option>
              <Option value="previousYear">Previous Year</Option>
            </OptGroup>
            <OptGroup key="custom1" label="Custom">
              <Option value="custom">Custom</Option>
            </OptGroup>
          </Select>
          <DatePicker
            value={startDate}
            onChange={this.setStartDate}
            style={{ width: 180, marginLeft: 8 }}
            format="DD MMM YYYY"
          />
          <DatePicker
            value={endDate}
            onChange={this.setEndDate}
            style={{ width: 180, marginLeft: 8 }}
            format="DD MMM YYYY"
          />
        </div>
        <div style={{ marginTop: 8, textAlign: "right" }}>
          <span
            style={{ cursor: "pointer", color: "steelblue" }}
            onClick={() => this.setState({ visible: false })}
          >
            Close
          </span>
        </div>
      </div>
    );
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  render() {
    const { text } = this.state;

    return (
      <Popover
        placement="bottom"
        title={<span>Filter Date</span>}
        content={this.content()}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <span style={{ cursor: "pointer" }}>
          {text} <Icon type="down" style={{ fontSize: 10 }} />
        </span>
      </Popover>
    );
  }
}

export default DateRangePicker;
