import React, { Component } from "react";
import { Icon, Spin, Tag, Menu, Divider, Dropdown } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isLoadingRequests, requestsSelector } from "../redux/selectors";
import { Table } from "../components/table";
import moment from "moment";
import { fetchRequests, fetchMembers, rejectRequest } from "../redux/actions";
import ViewRequest from "../components/ViewRequest";
import { reset } from "redux-form";
import { loadingIcon } from "../utils/loadingIcon";

const columns = [
  {
    title: "Request Date",
    key: "createdAt",
    align: "center",
    render: t => moment(t).format("DD MMM YYYY")
  },
  {
    title: "LM No",
    key: "LM No",
    align: "center"
  },
  {
    title: "Surname",
    key: "Surname"
  },
  {
    title: "NIC",
    key: "NIC",
    align: "center"
  },
  {
    title: "Status",
    key: "status",
    align: "center",
    render: t =>
      (t === "approved" && <Tag color="#389e0d">APPROVED</Tag>) ||
      (t === "rejected" && <Tag color="#f5222d">REJECTED</Tag>) ||
      (t === "pending" && <Tag color="#faad14">PENDING</Tag>)
  }
];

class RequestsPage extends Component {
  constructor() {
    super();
    this.state = {
      isRequestVisible: false,
      selectedFilter: "All"
    };
  }

  componentDidMount() {
    this.props.fetchRequests();
    this.props.fetchMembers();
  }

  getDataSource = () => {
    const { requests } = this.props;
    const { selectedFilter } = this.state;

    let filteredRequests = [];

    switch (selectedFilter) {
      case "All":
        filteredRequests = requests;
        break;
      case "Approved":
        filteredRequests = requests.filter(t => t.status === "approved");
        break;
      case "Pending":
        filteredRequests = requests.filter(t => t.status === "pending");
        break;
      case "Rejected":
        filteredRequests = requests.filter(t => t.status === "rejected");
        break;
      default:
        filteredRequests = requests;
    }

    return filteredRequests.map(t => ({
      ...t
    }));
  };

  onViewRequest = item => {
    this.setState({ initialRequestValues: item, isViewRequestVisible: true });
  };

  onCloseViewRequest = () => {
    this.setState({ isViewRequestVisible: false });
    this.props.resetForm();
  };

  onRequestSubmitSuccess = () => {
    this.setState({ isViewRequestVisible: false });
    this.props.resetForm();
  };

  onRejectRequest = values => {
    this.props.rejectRequest(values);
    this.setState({ isViewRequestVisible: false });
    this.props.resetForm();
  };

  handleFilterChange = ({ key }) => {
    this.setState({ selectedFilter: key });
  };

  render() {
    const { isLoading } = this.props;
    const { selectedFilter } = this.state;
    const dataSource = this.getDataSource();

    const filterOptions = (
      <Menu onClick={this.handleFilterChange}>
        <Menu.Item key="All">All</Menu.Item>
        <Menu.Item key="Approved">Approved</Menu.Item>
        <Menu.Item key="Pending">Pending</Menu.Item>
        <Menu.Item key="Rejected">Rejected</Menu.Item>
      </Menu>
    );

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <span style={{ fontWeight: 400, fontSize: 28 }}>Requests</span>
            <Divider type="vertical" />
            <Dropdown overlay={filterOptions} trigger={["click"]}>
              <span style={{ cursor: "pointer" }}>
                {selectedFilter} <Icon type="down" />
              </span>
            </Dropdown>
          </div>

          <div style={{ flex: 1, textAlign: "right" }}></div>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          onClick={this.onViewRequest}
        />

        <ViewRequest
          visible={this.state.isViewRequestVisible}
          onClose={this.onCloseViewRequest}
          initialValues={this.state.initialRequestValues}
          onReject={this.onRejectRequest}
          onSubmitSuccess={this.onRequestSubmitSuccess}
        />
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingRequests,
  requestsSelector,
  (isLoading, requests) => ({
    isLoading,
    requests
  })
);

const mapDispatchToProps = dispatch => {
  return {
    fetchRequests: () => dispatch(fetchRequests()),
    fetchMembers: () => dispatch(fetchMembers()),
    rejectRequest: payload => dispatch(rejectRequest(payload)),
    resetForm: () => dispatch(reset("request-form"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsPage);
