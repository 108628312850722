import * as types from "../actions/types";

let initialState = {
  isLoading: false,
  members: [],
  isLoadingMemberDetails: false,
  memberDetails: undefined,
  isApprovingMembers: false
};

export function membersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_MEMBERS:
      return { ...state, isLoading: true };
    case types.FETCH_MEMBERS_SUCCESS:
      return { ...state, members: payload, isLoading: false };
    case types.CREATE_MEMBER_SUCCESS:
      return { ...state, members: [...state.members, payload.newItem] };
    case types.UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.map(item => {
          if (item._id !== payload._id) {
            return item;
          }
          return {
            ...item,
            ...payload.item
          };
        })
      };
    case types.DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.filter(item => item._id !== payload._id)
      };
    case types.FIND_MEMBER_BY_NIC:
      return {
        ...state,
        isLoadingMemberDetails: true,
        memberDetails: undefined
      };
    case types.FIND_MEMBER_BY_NIC_SUCCESS:
      return {
        ...state,
        isLoadingMemberDetails: false,
        memberDetails: payload
      };
    case types.FIND_MEMBER_BY_NIC_FAILED:
      return {
        ...state,
        isLoadingMemberDetails: false
      };

    case types.APPROVE_MEMBERS:
      return { ...state, isApprovingMembers: true };
    case types.APPROVE_MEMBERS_SUCCESS:
      return { ...state, isApprovingMembers: false };
    case types.APPROVE_MEMBERS_FAILED:
      return { ...state, isApprovingMembers: false };

    default:
      return state;
  }
}
