import React, { Component } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { membersSchemaSelector } from "../redux/selectors";
import { hasUpdatePermission, hasDeletePermission } from "../utils/permissions";
import moment from "moment";

class ViewMember extends Component {
  render() {
    const { schema } = this.props;
    const member = this.props.selectedMember;

    if (!member) return <div />;

    return (
      <Modal
        visible={this.props.visible}
        onOk={this.props.onClose}
        onCancel={this.props.onClose}
        width="88vw"
        centered
        closable={false}
        footer={null}
      >
        <div style={{ maxHeight: "88vh" }}>
          <div style={{ display: "flex", marginBottom: 4 }}>
            <div style={{ flex: 1, textAlign: "left" }}>
              <span style={{ fontWeight: 400, fontSize: 24 }}>
                {member &&
                  `${member["LM No"]} - ${member.Title}. ${member.Surname} `}
              </span>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              {hasUpdatePermission("members") && (
                <Button
                  icon="edit"
                  style={{ marginRight: 8 }}
                  onClick={() => this.props.onUpdate(member)}
                >
                  Update
                </Button>
              )}
              {hasDeletePermission("members") && (
                <Button
                  icon="delete"
                  style={{ marginRight: 8 }}
                  onClick={() => this.props.onDelete(member)}
                >
                  Delete
                </Button>
              )}
              <Button
                icon="close"
                style={{ marginRight: 8 }}
                onClick={this.props.onClose}
                type="danger"
                shape="circle"
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 8,
              maxHeight: `calc(88vh - 80px)`,
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                {schema &&
                  schema.map((item, index) => (
                    <tr
                      key={index}
                      style={{ height: 32, borderBottom: "1px solid #eee" }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: 16,
                          fontWeight: "bold",
                          width: 220
                        }}
                      >
                        {item.name}
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: 16 }}>
                        {(item.type === "Date" &&
                          moment(member[item.name]).format("DD MMM YYYY")) ||
                          member[item.name]}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(
  membersSchemaSelector,
  schema => ({
    schema
  })
);

export default connect(mapStateToProps)(ViewMember);
