import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { membersSchemaSelector } from "../redux/selectors";
import moment from "moment";

const MemberDetails = ({ schema, member }) => (
  <table style={{ width: "100%" }}>
    <tbody>
      {schema.map((item, index) => (
        <tr key={index} style={{ height: 32, borderBottom: "1px solid #eee" }}>
          <td
            style={{ textAlign: "right", paddingRight: 16, fontWeight: "bold" }}
          >
            {item.name}
          </td>
          <td style={{ textAlign: "left", paddingLeft: 16 }}>
            {(item.type === "Date" &&
              moment(member[item.name]).format("DD MMM YYYY")) ||
              member[item.name]}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const mapStateToProps = createSelector(
  membersSchemaSelector,
  schema => ({
    schema
  })
);

export default connect(mapStateToProps)(MemberDetails);
