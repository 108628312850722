import React, { Component } from "react";
import { Row, Col, Icon } from "antd";
import { connect } from "react-redux";
import { authenticate } from "../redux/actions";
import Login from "../forms/login";
import Image from "react-image-resizer";

class LoginPage extends Component {
  constructor() {
    super();
    this.error = false;
  }

  handleSubmit = values => {
    this.props.authenticate(values);
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "linear-gradient(to right bottom, #1B38A8, #1976D2)",
          // backgroundImage: "url(assets/imgs/login_4.gif)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed"
        }}
      >
        <div
          style={{
            justifyContent: "center",
            width: 640,
            padding: 16,
            background: "white",
            borderRadius: 8
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Image
                img
                src={`assets/images/logo-white.png`}
                alt=""
                height={280}
                width={280}
                style={{ alignSelf: "center" }}
              />
            </Col>
            <Col span={12}>
              <div
                style={{
                  textAlign: "center",
                  fontSize: 24,
                  fontWeight: "bold"
                }}
              >
                <Icon type="login" />
                <span style={{ marginLeft: 16 }}>Sign In</span>
              </div>

              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 16,
                  fontSize: 16
                }}
              >
                Maris Stella College OBA Member Management System
              </div>

              <Login onSubmit={this.handleSubmit} loginError={this.error} />

              <div
                style={{
                  marginTop: 4,
                  cursor: "pointer",
                  fontSize: 13,
                  fontWeight: 600,
                  textAlign: "right"
                  // color: "#3949AB"
                }}
                onClick={() => (window.location = "/forgot-password")}
              >
                Forgot Password ?
              </div>

              <div style={{ textAlign: "center", marginTop: 36, fontSize: 12 }}>
                CODE[369] © 2019
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  authenticate
};

export default connect(
  null,
  mapDispatchToProps
)(LoginPage);
