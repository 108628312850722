import React, { Component } from "react";
import { Row, Col, Icon, Button, Spin, Input } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reportsSelector, isLoadingReports } from "../redux/selectors";
import { SimpleList } from "../components/simpleList";
import { deleteReport } from "../redux/actions";
import { showConfirmDeleteReport } from "../utils/confirmDialogs";

import { loadingIcon } from "../utils/loadingIcon";

const activity = [
  { label: "Activity Log", value: "Activity Log" },
  { label: "Deceased List", value: "Deceased List" },
  { label: "Missing NIC", value: "Missing NIC" }
];

class ReportsPage extends Component {
  constructor() {
    super();
    this.state = {
      searchQuery: ""
    };
  }

  filterReports(query) {
    const { reports } = this.props;
    if (query === "") {
      return reports;
    }
    const regex = new RegExp(
      `${query.toString().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}`,
      "i"
    );
    return reports.filter(
      report => report.name && report.name.search(regex) >= 0
    );
  }

  handleClick = item => {
    const { history } = this.props;
    switch (item) {
      case "Activity Log":
        history.push("/report-activity-log");
        break;
      case "Deceased List":
        history.push("/report-deceased-list");
        break;
      case "Missing NIC":
        history.push("/report-missing-nic");
        break;
      default:
        return;
    }
  };

  handleSavedItemClick = item => {
    const { reports, history } = this.props;
    let report = reports.find(t => t._id === item);
    history.push("/report-general", { report, newItem: false });
  };

  onDeleteReport = item => {
    this.props.deleteReport({ _id: item });
  };

  render() {
    const { isLoading } = this.props;
    const reports = this.filterReports(this.state.searchQuery);

    const reportsList = reports.map(t => ({
      label: t.name,
      value: t._id
    }));

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 16 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <span style={{ fontWeight: 400, fontSize: 28 }}>Reports</span>
            <Input.Search
              placeholder="Search"
              onChange={e => this.setState({ searchQuery: e.target.value })}
              style={{ width: 200, marginLeft: 16 }}
            />
          </div>

          <div style={{ flex: 1, textAlign: "right" }}>
            <Button
              onClick={() =>
                this.props.history.push("/report-general", { newItem: true })
              }
            >
              <Icon type="plus" />
              New Report
            </Button>
          </div>
        </div>

        <Row gutter={16} style={{ marginTop: 8 }}>
          <Col span={12}>
            <div style={{ fontSize: 18, fontWeight: 400, marginBottom: 4 }}>
              <Icon type="database" /> SAVED REPORTS
            </div>
            <div style={{ width: "80%" }}>
              <SimpleList
                dataSource={reportsList}
                onClick={this.handleSavedItemClick}
                showDelete
                onDelete={item =>
                  showConfirmDeleteReport(() => this.onDeleteReport(item))
                }
              />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ fontSize: 18, fontWeight: 400, marginBottom: 4 }}>
              <Icon type="bars" /> ACTIVITY
            </div>
            <div style={{ width: "80%" }}>
              <SimpleList dataSource={activity} onClick={this.handleClick} />
            </div>
          </Col>
        </Row>
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingReports,
  reportsSelector,
  (isLoading, reports) => ({
    isLoading,
    reports
  })
);

const mapDispatchToProps = dispatch => {
  return {
    deleteReport: report => dispatch(deleteReport(report))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsPage);
