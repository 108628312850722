import React, { Component, Fragment } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Field, reduxForm } from "redux-form";
import { Input } from "../../utils/formComponents";
import { required } from "../../utils/validators";

class ReportForm extends Component {
  render() {
    const { handleSubmit, initialValues } = this.props;
    const filters = initialValues && initialValues.filters;

    return (
      <form onSubmit={handleSubmit}>
        <h1>{(this.props.newItem && "Save Report") || "Update Report"}</h1>

        <div style={{ marginBottom: 8 }}>
          <span className="bold-text">Report Name</span>
          <Field name="name" component={Input} validate={[required]} />
        </div>

        {filters.length > 0 && (
          <div>
            <span className="bold-text">Filters</span>
            <div>
              {filters.map((item, index) => (
                <Fragment key={index}>
                  <span className="bold-text">{` ${item.logic || ""} `}</span>
                  <span>{` ${item.key || ""} `}</span>
                  <span className="bold-text">{` ${item.comparator ||
                    ""} `}</span>
                  <span>{` "${item.value || ""}" `}</span>
                </Fragment>
              ))}
            </div>
          </div>
        )}

        <div
          style={{
            width: "100%",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            marginTop: 16,
            marginBottom: -16
          }}
        >
          <Button
            style={{ marginRight: 8, width: 100 }}
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
          <Button style={{ width: 100 }} onClick={handleSubmit} type="primary">
            {(this.props.newItem && "Save") || "Update"}
          </Button>
        </div>
      </form>
    );
  }
}

ReportForm = reduxForm({
  form: "report-form"
})(ReportForm);

let initialValues = {};

const mapStateToProps = createSelector(
  (state, props) => ({ ...initialValues, ...props.initialValues }),
  initialValues => ({
    initialValues
  })
);

export default connect(mapStateToProps)(ReportForm);
