import * as types from "./types";

export function getSettings() {
  return {
    type: types.GET_SETTINGS
  };
}

export function setSettings(payload) {
  return {
    type: types.SET_SETTINGS,
    payload
  };
}
