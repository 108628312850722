import React, { Component } from "react";
import { Button } from "antd";
import MemberDetailsForm from "../forms/memberDetails";
import { findMemberByNIC } from "../redux/actions";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  isLoadingMemberDetails,
  memberDetailsSelector
} from "../redux/selectors";
import MemberDetails from "../components/MemberDetails";
import Member from "../forms/member";
import { reset } from "redux-form";
import Image from "react-image-resizer";

class FindMemberPage extends Component {
  constructor() {
    super();
    this.state = {
      isMemberVisible: false
    };
  }

  onMember = () => {
    this.updateRequest = true;
    const { member } = this.props;
    this.setState({ initialMemberValues: member, isMemberVisible: true });
  };

  onCloseMember = () => {
    this.setState({ isMemberVisible: false });
  };

  onMemberSubmitSuccess = () => {
    this.setState({ isMemberVisible: false });
    this.props.resetForm();
  };

  onSubmit = values => {
    const req = { nic: values.nic && values.nic.trim() };
    this.props.findMemberByNIC(req);
  };

  render() {
    const { member } = this.props;

    return (
      <div style={{ padding: 16 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image
            img
            src={`assets/images/logo-white.png`}
            alt="logo"
            height={160}
            width={160}
            style={{ alignSelf: "center" }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <span
            style={{
              fontSize: 20,
              fontWeight: 600,
              textAlign: "center",
              color: "#333"
            }}
          >
            Maris Stella College Old Boys Association
          </span>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center", color: "#333" }}
        >
          <ul>
            <li style={{ fontSize: 14, fontWeight: 500, marginTop: 8 }}>
              Are you a member of OBA? If yes, please search your details by
              entering your NIC. If your details are incorrect please do the
              changes and submit using the submit button.
            </li>
            <li style={{ fontSize: 14, fontWeight: 500, marginTop: 8 }}>
              If you can’t find your details, then please send an email to{" "}
              <a href="mailto:mscoba.membermgt@gmail.com?subject = Update Details&body = Enter your Name/s, Surname, Address, Membership No (if known), NIC Number, Contact Number, Email">
                mscoba.membermgt@gmail.com
              </a>{" "}
              with the below details. We will revert to you soon.
              <ul>
                <li>Name/s</li>
                <li>Surname</li>
                <li>Address</li>
                <li>Membership No (if known)</li>
                <li>NIC Number</li>
                <li>Contact Number</li>
                <li>Email Address</li>
              </ul>
            </li>
            <li style={{ fontSize: 14, fontWeight: 500, marginTop: 8 }}>
              All updates to membership records are subject to the approval of
              the Membership Committee of the OBA. Your updated record will be
              sent to the committee as an update request where each request will
              be reviewed and approved on a case by case basis.
            </li>
            <li style={{ fontSize: 14, fontWeight: 500, marginTop: 8 }}>
              <span style={{ fontWeight: "bold" }}>
                Note: Date of Birth (DOB) records have been reset to 2020,
                Please update the membership record with your correct Date of
                Birth.
              </span>
            </li>
          </ul>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <MemberDetailsForm onSubmit={this.onSubmit} />
        </div>

        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 8 }}
        >
          {member && (
            <div style={{ marginTop: 8 }}>
              <MemberDetails member={member} />
              <div style={{ marginTop: 16, textAlign: "center" }}>
                <Button type="primary" onClick={this.onMember}>
                  Request Change
                </Button>
              </div>
            </div>
          )}
        </div>

        <Member
          visible={this.state.isMemberVisible}
          onClose={this.onCloseMember}
          onSubmitSuccess={this.onMemberSubmitSuccess}
          initialValues={this.state.initialMemberValues}
          enableReinitialize
          updateRequest={this.updateRequest}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMemberDetails,
  memberDetailsSelector,
  (isLoading, member) => ({
    isLoading,
    member
  })
);

const mapDispatchToProps = dispatch => {
  return {
    findMemberByNIC: nic => dispatch(findMemberByNIC(nic)),
    resetForm: () => dispatch(reset("member-form"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindMemberPage);
