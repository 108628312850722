import React, { Component } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Field, reduxForm } from "redux-form";
import { Input, Select } from "../../utils/formComponents";
import { required, number } from "../../utils/validators";

class PrintForm extends Component {
  render() {
    const { handleSubmit, reset } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: 8 }}>
          <span className="bold-text">Page Size</span>
          <Field
            name="pageSize"
            component={Select}
            options={[
              { label: "A4", value: "A4" },
              { label: "A3", value: "A3" },
              { label: "A2", value: "A2" },
              { label: "LETTER", value: "LETTER" },
              { label: "LEGAL", value: "LEGAL" }
            ]}
            style={{ width: "100%" }}
            validate={[required]}
          />
        </div>

        <div style={{ marginBottom: 8 }}>
          <span className="bold-text">Font Size</span>
          <Field
            name="fontSize"
            component={Input}
            validate={[required, number]}
          />
        </div>

        <div style={{ marginBottom: 8 }}>
          <span className="bold-text">Box Width (mm)</span>
          <Field name="width" component={Input} validate={[required, number]} />
        </div>

        <div style={{ marginBottom: 8 }}>
          <span className="bold-text">Box Height (mm)</span>
          <Field
            name="height"
            component={Input}
            validate={[required, number]}
          />
        </div>

        <div
          style={{
            width: "100%",
            padding: "10px 0px",
            background: "#fff",
            textAlign: "left",
            marginTop: 16,
            marginBottom: -16
          }}
        >
          <Button style={{ marginRight: 8, width: 80 }} onClick={reset}>
            Reset
          </Button>
          <Button style={{ width: 80 }} onClick={handleSubmit} type="primary">
            Apply
          </Button>
        </div>
      </form>
    );
  }
}

PrintForm = reduxForm({
  form: "print-form"
})(PrintForm);

let initialValues = {
  pageSize: "A4",
  fontSize: 11,
  width: 90,
  height: 36
};

const mapStateToProps = createSelector(
  (state, props) => ({ ...initialValues, ...props.initialValues }),
  initialValues => ({
    initialValues
  })
);

export default connect(mapStateToProps)(PrintForm);
