import { createSelector } from "reselect";

export const isLoadingSettings = createSelector(
  state => state.settings.isLoading,
  isLoading => isLoading
);

export const settingsSelector = createSelector(
  state => state.settings.settings,
  settings => settings
);

export const membersSettingsSelector = createSelector(
  state => state.settings.settings,
  settings => settings && settings.members
);

export const lmNoSettingsSelector = createSelector(
  state => state.settings.settings,
  settings => settings && settings.lmNo
);

export const membersSchemaSelector = createSelector(
  state => state.settings.settings,
  settings =>
    settings && settings.members && selectMembersSchema(settings.members)
);

function selectMembersSchema(members) {
  return JSON.parse(members.schema);
}
