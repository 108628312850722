import React, { Component } from "react";
import { Modal } from "antd";
import ReportForm from "./ReportForm";
import { connect } from "react-redux";
import { createReport, updateReport } from "../../redux/actions";

class Report extends Component {
  handleSubmit = values => {
    if (this.props.newItem) {
      this.props.createReport(values);
    } else {
      this.props.updateReport(values);
    }
  };

  render() {
    return (
      <Modal
        width="64vw"
        footer={null}
        onCancel={this.props.onClose}
        {...this.props}
      >
        <ReportForm onSubmit={this.handleSubmit} {...this.props} />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  createReport,
  updateReport
};

export default connect(
  null,
  mapDispatchToProps
)(Report);
