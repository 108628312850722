import * as types from "./types";

export function fetchRequests() {
  return {
    type: types.FETCH_REQUESTS
  };
}

export function createRequest(payload) {
  return {
    type: types.CREATE_REQUEST,
    payload
  };
}

export function updateRequest(payload) {
  return {
    type: types.UPDATE_REQUEST,
    payload
  };
}

export function deleteRequest(payload) {
  return {
    type: types.DELETE_REQUEST,
    payload
  };
}

export function deleteManyRequests(payload) {
  return {
    type: types.DELETE_MANY_REQUESTS,
    payload
  };
}

export function approveRequest(payload) {
  return {
    type: types.APPROVE_REQUEST,
    payload
  };
}

export function rejectRequest(payload) {
  return {
    type: types.REJECT_REQUEST,
    payload
  };
}
