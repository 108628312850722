import React, { Component } from "react";
import { Spin, Icon, Dropdown, Menu } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isLoadingMembers, approvedMembersSelector } from "../redux/selectors";
import { Table } from "../components/table";
import moment from "moment";

import { loadingIcon } from "../utils/loadingIcon";

// const arrSum = arr => arr.reduce((a, b) => a + b, 0);

const columns = [
  {
    title: "LM No",
    key: "LM No",
    align: "center"
  },
  {
    title: "Title",
    key: "Title",
    align: "center"
  },
  {
    title: "Surname",
    key: "Surname",
    align: "left"
  },
  {
    title: "Full Name",
    key: "Full Name",
    align: "left"
  },
  {
    title: "NIC",
    key: "NIC",
    align: "center"
  },
  {
    title: "Approved At",
    key: "approvedAt",
    align: "center",
    render: t => moment(t).format("DD MMM YYYY, h:mm A")
  },
  {
    title: "Approved By",
    key: "approvedBy",
    align: "center"
  }
];

class RecentlyJoinedMembersCard extends Component {
  constructor() {
    super();
    this.state = {
      selectedFilter: "Last 30 Days",
      days: 30
    };
  }

  getDataSource = () => {
    const { members } = this.props;
    const { days } = this.state;

    const recentMembers = members.filter(
      t => t.approvedAt && this.getTimeDifference(t.approvedAt) <= days
    );
    return recentMembers.sort((a, b) =>
      a.approvedAt > b.approvedAt ? -1 : b.approvedAt > a.approvedAt ? 1 : 0
    );
  };

  getTimeDifference = approvedAt => {
    let now = moment();
    let approvedDate = moment(approvedAt);
    let duration = moment.duration(now.diff(approvedDate)).asDays();
    return Math.round(duration);
  };

  handleFilterChange = ({ key }) => {
    let days = 0;
    switch (key) {
      case "Last 14 Days":
        days = 14;
        break;
      case "Last 30 Days":
        days = 30;
        break;
      case "Last 90 Days":
        days = 90;
        break;
      case "Last 365 Days":
        days = 365;
        break;
      default:
        days = 30;
    }
    this.setState({ selectedFilter: key, days });
  };

  render() {
    const { isLoading } = this.props;
    const { selectedFilter } = this.state;
    const dataSource = this.getDataSource();

    const filterOptions = (
      <Menu onClick={this.handleFilterChange}>
        <Menu.Item key="Last 14 Days">Last 14 Days</Menu.Item>
        <Menu.Item key="Last 30 Days">Last 30 Days</Menu.Item>
        <Menu.Item key="Last 90 Days">Last 90 Days</Menu.Item>
        <Menu.Item key="Last 365 Days">Last 365 Days</Menu.Item>
      </Menu>
    );

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div
          style={{
            background: "#fff",
            padding: 16,
            minHeight: 240,
            borderRadius: 6,
            boxShadow: "1px 2px 4px rgba(100, 100, 100, .2)"
          }}
        >
          <div style={{ display: "flex", marginBottom: 8 }}>
            <div style={{ flex: 1, textAlign: "left" }}>
              <span style={{ fontWeight: 400, fontSize: 24 }}>
                Recently Approved Members
              </span>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <Dropdown overlay={filterOptions} trigger={["click"]}>
                <span style={{ cursor: "pointer" }}>
                  {selectedFilter} <Icon type="down" />
                </span>
              </Dropdown>
            </div>
          </div>

          <Table columns={columns} dataSource={dataSource} hideSearch />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMembers,
  approvedMembersSelector,
  (isLoading, members) => ({
    isLoading,
    members
  })
);

export default connect(mapStateToProps)(RecentlyJoinedMembersCard);
