import React, { Component, Fragment } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  Input,
  DatePicker,
  Select,
  SelectSearch
} from "../../utils/formComponents";
import { required, number } from "../../utils/validators";
import { createSelector } from "reselect";
import { membersSchemaSelector, membersSelector } from "../../redux/selectors";
import { isMobile } from "react-device-detect";

const FormInput = props => (
  <div style={{ marginBottom: 8 }}>
    <div className="bold-text">
      {props.label}{" "}
      {props.validate.includes(required) && (
        <span style={{ color: "red" }}>*</span>
      )}
    </div>
    <Field name={props.name} component={Input} {...props} />
  </div>
);

const FormDatePicker = props => (
  <div style={{ marginBottom: 8 }}>
    <div className="bold-text">
      {props.label}{" "}
      {props.validate.includes(required) && (
        <span style={{ color: "red" }}>*</span>
      )}
    </div>
    <Field
      name={props.name}
      component={DatePicker}
      style={{ width: "100%" }}
      {...props}
    />
  </div>
);

const FormSelect = props => (
  <div style={{ marginBottom: 8 }}>
    <div className="bold-text">
      {props.label}{" "}
      {props.validate.includes(required) && (
        <span style={{ color: "red" }}>*</span>
      )}
    </div>
    <Field
      name={props.name}
      component={Select}
      style={{ width: "100%" }}
      {...props}
    />
  </div>
);

const FormSelectSearch = props => (
  <div style={{ marginBottom: 8 }}>
    <div className="bold-text">
      {props.label}{" "}
      {props.validate.includes(required) && (
        <span style={{ color: "red" }}>*</span>
      )}
    </div>
    <Field
      name={props.name}
      component={SelectSearch}
      style={{ width: "100%" }}
      {...props}
    />
  </div>
);

class MemberForm extends Component {
  getValidators = item => {
    let validators = [];
    if (item.required === "Yes") validators.push(required);
    if (item.type === "Number") validators.push(number);
    if (item.type === "Date") validators.push(required);
    return validators;
  };

  getOptions = item => {
    let options = [];
    let ops = item.options.split(",");
    ops.forEach(t => {
      options.push({ label: t, value: t });
    });
    return options;
  };

  getSelectSearchOptions = () => {
    const { members } = this.props;
    const memberOptions = members.map(item => ({
      label: item["Full Name"],
      value: item["Full Name"]
    }));
    return memberOptions;
  };

  shouldDisable = item => {
    if (!this.props.newItem && item.editable === "No") return true;

    if (this.props.updateRequest && item.memberEditable === "No") return true;

    return false;
  };

  isInputVisibleInForm = item => {
    if (this.props.newItem && item.name === "LM No") return false;

    if (this.props.updateRequest && item.show === "No") return false;

    if (item.type === "String" || item.type === "Number") return true;
  };

  render() {
    const { handleSubmit, schema } = this.props;

    return (
      <form
        onSubmit={handleSubmit}
        style={{
          overflowY: "visible",
          overflowX: "hidden",
          height: "100vh",
          margin: -20,
          padding: 20
        }}
      >
        <h1 style={{ textAlign: "center" }}>
          {(this.props.updateRequest && "Update Details") ||
            (this.props.newItem && "Add Member") ||
            "Update Member"}
        </h1>

        <div style={{ width: isMobile ? "100%" : "48%", margin: "0 auto" }}>
          {schema &&
            schema.map((item, index) => (
              <Fragment key={index}>
                {this.isInputVisibleInForm(item) && (
                  <FormInput
                    name={item.name}
                    label={item.name}
                    validate={this.getValidators(item)}
                    disabled={this.shouldDisable(item)}
                  />
                )}
                {item.type === "Date" && (
                  <FormDatePicker
                    name={item.name}
                    label={item.name}
                    validate={this.getValidators(item)}
                    disabled={this.shouldDisable(item)}
                  />
                )}
                {item.type === "Select" && (
                  <FormSelect
                    name={item.name}
                    label={item.name}
                    options={this.getOptions(item)}
                    validate={this.getValidators(item)}
                    disabled={this.shouldDisable(item)}
                  />
                )}
                {item.type === "AutoComplete" && (
                  <FormSelectSearch
                    name={item.name}
                    label={item.name}
                    options={this.getSelectSearchOptions()}
                    validate={this.getValidators(item)}
                    disabled={this.shouldDisable(item)}
                  />
                )}
              </Fragment>
            ))}
        </div>

        <div
          style={{
            width: "100%",
            padding: "48px 0px 32px 0px",
            textAlign: "center"
          }}
        >
          <Button
            onClick={this.props.onClose}
            style={{ marginRight: 8, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            style={{ marginRight: 8, width: 100 }}
          >
            {(this.props.updateRequest && "Submit") ||
              (this.props.newItem && "Save") ||
              "Update"}
          </Button>
        </div>
      </form>
    );
  }
}

MemberForm = reduxForm({
  form: "member-form",
  enableReinitialize: true
})(MemberForm);

// const selector = formValueSelector("member-form");

let initialValues = {
  Country: "Sri Lanka",
  Deceased: "No"
};

const mapStateToProps = createSelector(
  membersSchemaSelector,
  membersSelector,
  (state, props) => ({ ...initialValues, ...props.initialValues }),
  (schema, members, initialValues) => ({
    schema,
    members,
    initialValues
  })
);

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change("member-form", field, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberForm);
