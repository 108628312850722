import { createSelector } from "reselect";

export const isLoadingGroups = createSelector(
  state => state.groups.isLoading,
  isLoading => isLoading
);

export const groupsSelector = createSelector(
  state => state.groups.groups,
  groups => groups
);
