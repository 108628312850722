import React, { Component } from "react";
import { Button, Divider, Spin } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { activityLogSelector, isLoadingActivityLog } from "../redux/selectors";
import { Table } from "../components/table";
import moment from "moment";
import DateRangePicker from "../components/dateRangePicker";
import { fetchActivityLog } from "../redux/actions";
import { loadingIcon } from "../utils/loadingIcon";

const columns = [
  {
    title: "Date",
    key: "createdAt",
    align: "center",
    width: 120,
    render: t => moment(t).format("DD MMM YYYY")
  },
  {
    title: "Time",
    key: "createdAt",
    align: "center",
    width: 120,
    render: t => moment(t).format("h:mm:ss A")
  },
  {
    title: "User",
    key: "user.username",
    align: "center",
    width: 120
  },
  {
    title: "Log",
    key: "log"
  },
  {
    title: "Remarks",
    key: "remarks",
    width: 420
  }
];

class ActivityLog extends Component {
  constructor() {
    super();
    this.state = {
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month")
    };
  }

  componentDidMount() {
    this.props.fetchActivityLog();
  }

  getActivityLog = () => {
    const { activityLog } = this.props;
    const { startDate, endDate } = this.state;
    let logs = activityLog.map(t => ({
      ...t
    }));
    return logs.filter(t => moment(t.createdAt).isBetween(startDate, endDate));
  };

  render() {
    const dataSource = this.getActivityLog();
    const { isLoading } = this.props;

    return (
      <div>
        <Spin spinning={isLoading} indicator={loadingIcon}>
          <div style={{ display: "flex", marginBottom: 16 }}>
            <div style={{ flex: 1, textAlign: "left" }}>
              <Button
                icon="arrow-left"
                onClick={() => this.props.history.goBack()}
              />
              <Divider type="vertical" />
              <span style={{ fontWeight: 400, fontSize: 24 }}>
                {" "}
                Activity Log
              </span>
              <Divider type="vertical" />
              <DateRangePicker
                style={{ marginLeft: 8 }}
                onSelect={({ startDate, endDate }) =>
                  this.setState({ startDate, endDate })
                }
              />
            </div>
          </div>

          <Table columns={columns} dataSource={dataSource} />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingActivityLog,
  activityLogSelector,
  (isLoading, activityLog) => ({
    isLoading,
    activityLog
  })
);

const mapDispatchToProps = {
  fetchActivityLog
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityLog);
