import * as types from "../actions/types";

let initialState = {
  isLoading: false,
  settings: []
};

export function settingsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_SETTINGS:
      return { ...state, isLoading: true };
    case types.GET_SETTINGS_SUCCESS:
      return { ...state, settings: payload, isLoading: false };

    default:
      return state;
  }
}
