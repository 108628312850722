import React, { Component } from "react";
import { Modal } from "antd";
import UserForm from "./UserForm";
import { connect } from "react-redux";
import { createUser, updateUser } from "../../redux/actions";

class User extends Component {
  handleSubmit = values => {
    if (this.props.newItem) {
      this.props.createUser(values);
    } else {
      this.props.updateUser(values);
    }
  };

  render() {
    return (
      <Modal
        width="64vw"
        {...this.props}
        footer={null}
        onCancel={this.props.onClose}
      >
        <UserForm onSubmit={this.handleSubmit} {...this.props} />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  createUser,
  updateUser
};

export default connect(
  null,
  mapDispatchToProps
)(User);
