import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isLoadingMembers, membersSelector } from "../redux/selectors";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { loadingIcon } from "../utils/loadingIcon";

// const arrSum = arr => arr.reduce((a, b) => a + b, 0);

class YearVsMembersLineCard extends Component {
  constructor() {
    super();
    this.state = {};
  }

  getDataset = () => {
    let { members } = this.props;

    members = members.sort((a, b) =>
      a["Joined Date"] > b["Joined Date"]
        ? 1
        : b["Joined Date"] > a["Joined Date"]
        ? -1
        : 0
    );

    let years = [];
    let total = [];

    members &&
      members.forEach(member => {
        let year = moment(member["Joined Date"]).format("YYYY");
        let found = years.find(t => t === year);
        if (!found) {
          years.push(year);
          total.push(1);
        } else {
          let index = years.findIndex(t => t === found);
          total[index] += 1;
        }
      });

    for (let i = 1; i < total.length; i++) {
      total[i] = total[i] + total[i - 1];
    }

    return { years, total };
  };

  render() {
    const { isLoading } = this.props;

    const { years, total } = this.getDataset();

    let barData = {
      labels: years,
      datasets: [
        {
          label: "Members",
          backgroundColor: "#7a5195",
          fill: false,
          pointRadius: 2,
          borderColor: "#7a5195",
          borderWidth: 1,
          hoverBackgroundColor: "#FF9E43",
          hoverBorderColor: "#FF9E43",
          data: total
        }
      ]
    };

    return (
      <div
        style={{
          background: "#fff",
          padding: 16,
          minHeight: 240,
          borderRadius: 6,
          boxShadow: "1px 2px 4px rgba(100, 100, 100, .2)"
        }}
      >
        <Spin spinning={isLoading} indicator={loadingIcon}>
          <div style={{ display: "flex", marginBottom: 4 }}>
            <div style={{ flex: 1, textAlign: "left" }}>
              <div
                style={{ textAlign: "center", fontWeight: 400, fontSize: 16 }}
              >
                Members vs Years
              </div>
            </div>
          </div>

          <Line
            data={barData}
            height={128}
            options={{
              legend: {
                position: "top",
                labels: { boxWidth: 20, fontSize: 9, usePointStyle: true }
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      suggestedMin: 0
                    }
                  }
                ],
                xAxes: [
                  {
                    type: "time",
                    time: {
                      unit: "year",
                      displayFormats: {
                        quarter: "DD MMM YYYY"
                      }
                    }
                  }
                ]
              }
            }}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMembers,
  membersSelector,
  (isLoading, members) => ({
    isLoading,
    members
  })
);

export default connect(mapStateToProps)(YearVsMembersLineCard);
