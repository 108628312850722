import * as types from "../actions/types";

let initialState = {
  auth: undefined,
  isLoading: false,
  success: false,
  isVerifying: false,
  verified: undefined
};

export function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.AUTHENTICATE:
      return { ...state, isLoading: true, success: false };
    case types.AUTHENTICATE_SUCCESS:
      return { ...state, auth: payload, isLoading: false, success: true };
    case types.AUTHENTICATE_FAILED:
      return { ...state, auth: undefined, isLoading: false, success: false };

    case types.VERIFY_EMAIL:
      return { ...state, verifying: true, verified: undefined };
    case types.VERIFY_EMAIL_SUCCESS:
      return { ...state, verifying: false, verified: true };
    case types.VERIFY_EMAIL_FAILED:
      return { ...state, verifying: false, verified: false };

    default:
      return state;
  }
}
