import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* fetchUsers() {
  try {
    const users = yield call(sendRequest, "users", "GET");
    yield put({ type: types.FETCH_USERS_SUCCESS, payload: users.data });
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* createUser({ payload }) {
  try {
    const response = yield call(sendRequest, "users/register", "POST", payload);
    yield put({ type: types.FETCH_USERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* updateUser({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `users/${payload._id}`,
      "PUT",
      payload
    );
    yield put({ type: types.FETCH_USERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* deleteUser({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `users/${payload._id}`,
      "DELETE",
      payload
    );
    yield put({ type: types.FETCH_USERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* usersSagaWatcher() {
  yield takeLatest(types.FETCH_USERS, fetchUsers);
  yield takeEvery(types.CREATE_USER, createUser);
  yield takeEvery(types.UPDATE_USER, updateUser);
  yield takeEvery(types.DELETE_USER, deleteUser);
}
