import { notification } from "antd";

export const notifySuccess = ({ message, description }) => {
  notification.success({
    message: message || "Success",
    description: description
  });
};

export const notifyError = ({ message, description }) => {
  notification.error({
    message: message || "Something Went Wrong",
    description: description
  });
};
