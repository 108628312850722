import { fork } from "redux-saga/effects";

import { membersSagaWatcher } from "./membersSaga";
import { authSagaWatcher } from "./authSaga";
import { requestsSagaWatcher } from "./requestsSaga";
import { actionsSagaWatcher } from "./actionsSaga";
import { reportsSagaWatcher } from "./reportsSaga";
import { settingsSagaWatcher } from "./settingsSaga";
import { usersSagaWatcher } from "./usersSaga";
import { rolesSagaWatcher } from "./rolesSaga";
import { activityLogSagaWatcher } from "./activityLogSaga";
import { groupsSagaWatcher } from "./groupsSaga";

export function* rootSaga() {
  yield fork(membersSagaWatcher);
  yield fork(authSagaWatcher);
  yield fork(requestsSagaWatcher);
  yield fork(actionsSagaWatcher);
  yield fork(reportsSagaWatcher);
  yield fork(settingsSagaWatcher);
  yield fork(usersSagaWatcher);
  yield fork(rolesSagaWatcher);
  yield fork(activityLogSagaWatcher);
  yield fork(groupsSagaWatcher);
}
