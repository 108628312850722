import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* getSettings() {
  try {
    const response = yield call(sendRequest, "settings/get", "GET");
    yield put({ type: types.GET_SETTINGS_SUCCESS, payload: response.data });
  } catch (error) {
    yield notifyError(error.response.data);
    if (error.response.status === 401) {
      // unauthorized
      localStorage.clear();
      window.location.href = "/login";
    }
  }
}

function* setSettings({ payload }) {
  try {
    let dotNot = dotNotate(payload);
    const response = yield call(sendRequest, "settings/set", "POST", dotNot);
    yield put({ type: types.GET_SETTINGS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* settingsSagaWatcher() {
  yield takeLatest(types.GET_SETTINGS, getSettings);
  yield takeEvery(types.SET_SETTINGS, setSettings);
}

function dotNotate(obj, target = {}, prefix = "") {
  Object.keys(obj).forEach(function(key) {
    if (typeof obj[key] === "object") {
      dotNotate(obj[key], target, prefix + key + ".");
    } else {
      return (target[prefix + key] = obj[key]);
    }
  });
  return target;
}
