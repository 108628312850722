import React, { Component } from "react";
import { Button } from "antd";
import { reduxForm, Field } from "redux-form";
import { Input } from "../../utils/formComponents";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  isLoadingMemberDetails,
  memberDetailsSelector
} from "../../redux/selectors";
import { getSettings } from "../../redux/actions";

class MemberDetailsForm extends Component {
  componentDidMount() {
    this.props.getSettings();
  }

  onKeyPress = event => {
    if (event.key === "Enter") {
      this.props.handleSubmit();
    }
  };

  render() {
    const { handleSubmit, isLoading } = this.props;

    return (
      <form onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
        <div style={{ display: "flex", marginTop: 16 }}>
          <Field
            name="nic"
            component={Input}
            placeholder="Enter NIC No"
            style={{ width: 200 }}
          />
          <Button
            style={{ width: 100, marginLeft: 16 }}
            type="primary"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Search
          </Button>
        </div>
      </form>
    );
  }
}

MemberDetailsForm = reduxForm({
  form: "member-details-form"
})(MemberDetailsForm);

const mapStateToProps = createSelector(
  isLoadingMemberDetails,
  memberDetailsSelector,
  (isLoading, memberDetails) => ({
    isLoading,
    memberDetails
  })
);

const mapDispatchToProps = {
  getSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDetailsForm);
