import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { forgotPassword } from "../redux/actions";
import {
  isVerifyingEmailSelector,
  emailVerifiedSelector
} from "../redux/selectors";
import ForgotPasswordForm from "../forms/forgotPassword";

class ForgotPasswordPage extends Component {
  onSubmit = values => {
    this.props.forgotPassword(values);
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "linear-gradient(to right bottom, #1B38A8, #1976D2)",
          // backgroundImage: "url(assets/imgs/login_4.gif)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed"
        }}
      >
        <div
          style={{
            textAlign: "center",
            width: 480,
            padding: 16,
            background: "white",
            borderRadius: 8
          }}
        >
          <div style={{ fontSize: 28, fontWeight: "bold" }}>
            Forgot Your Password?
          </div>
          <div style={{ fontSize: 16, marginTop: 16 }}>
            Enter your email address below and we will send you instructions on
            how to change your password
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
              marginBottom: 32
            }}
          >
            <ForgotPasswordForm onSubmit={this.onSubmit} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isVerifyingEmailSelector,
  emailVerifiedSelector,
  (isVerifyingEmail, emailVerified) => ({
    isVerifyingEmail,
    emailVerified
  })
);

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: email => dispatch(forgotPassword(email))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordPage);
