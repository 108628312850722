import React, { Component } from "react";
import { Button, Tag, Upload, Icon } from "antd";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { Input, Editor } from "../../utils/formComponents";
import { required } from "../../utils/validators";
var toBuffer = require("blob-to-buffer");

// const Dragger = Upload.Dragger;

class SendEmailForm extends Component {
  constructor() {
    super();
    this.state = {
      attachments: [],
      fileList: []
    };
  }

  getBufferArray = data => {
    return new Promise((resolve, reject) => {
      toBuffer(data, function(err, buffer) {
        if (err) reject(err);

        resolve(buffer);
      });
    });
  };

  onFileUpload = async file => {
    let buffer = await this.getBufferArray(file);

    let attachments = JSON.parse(JSON.stringify(this.state.attachments));

    attachments.push({
      filename: file.name,
      content: buffer,
      contentType: file.type
    });

    this.setState(state => ({
      fileList: [...state.fileList, file]
    }));

    this.setState({ attachments });
    this.props.changeFieldValue("attachments", attachments);

    return false;
  };

  onFileRemove = file => {
    this.setState(state => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      const newAttachments = state.attachments.slice();
      newFileList.splice(index, 1);
      newAttachments.splice(index, 1);
      this.props.changeFieldValue("attachments", newAttachments);

      return {
        fileList: newFileList,
        attachments: newAttachments
      };
    });
  };

  render() {
    const { handleSubmit, initialValues } = this.props;

    const to =
      initialValues &&
      initialValues.to &&
      initialValues.to.filter(t => t !== null && t !== undefined);

    return (
      <form onSubmit={handleSubmit}>
        <h1>Send Email</h1>

        <div style={{ marginTop: 16, marginBottom: 8 }}>
          <span className="bold-text">To: </span>
          {to.map((item, index) => (
            <Tag key={index}>{item}</Tag>
          ))}
        </div>

        <div style={{ marginBottom: 16 }}>
          <span className="bold-text">Subject</span>
          <Field name="subject" component={Input} validate={[required]} />
        </div>

        <div style={{ marginBottom: 8 }}>
          <Field
            name="html"
            component={Editor}
            style={{ height: 360, marginBottom: 48 }}
          />
        </div>

        <Upload
          fileList={this.state.fileList}
          beforeUpload={file => this.onFileUpload(file)}
          onRemove={file => this.onFileRemove(file)}
          multiple={true}
        >
          <Button>
            <Icon type="file-add" /> Attach Files
          </Button>
        </Upload>

        <div
          style={{
            width: "100%",
            padding: "16px 0px",
            background: "#fff",
            textAlign: "right"
          }}
        >
          <Button
            style={{ marginRight: 8, width: 100 }}
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
          <Button style={{ width: 100 }} onClick={handleSubmit} type="primary">
            Send
          </Button>
        </div>
      </form>
    );
  }
}

SendEmailForm = reduxForm({
  form: "send-email-form"
})(SendEmailForm);

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change("send-email-form", field, value));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(SendEmailForm);
