import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import decode from "jwt-decode";
import App from "./App";

import LoginPage from "./pages/LoginPage";
import FindMemberPage from "./pages/FindMemberPage";
import DashboardPage from "./pages/DashboardPage";
import MembersPage from "./pages/MembersPage";
import RequestsPage from "./pages/RequestsPage";
import GroupsPage from "./pages/GroupsPage";
import ReportsPage from "./pages/ReportsPage";
import SettingsPage from "./pages/SettingsPage";
import UsersAndRolesPage from "./pages/UsersAndRolesPage";
import VerifyUserPage from "./pages/VerifyUserPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";

import ImportMembers from "./components/ImportMembers";
import ApproveMembers from "./components/ApproveMembers";

import ActivityLog from "./reports/ActivityLog";
import ReportGeneral from "./reports/ReportGeneral";
import GroupGeneral from "./reports/GroupGeneral";
import DeceasedList from "./reports/DeceasedList";

import { hasReadPermission } from "./utils/permissions";
import MissingNIC from "./reports/MissingNIC";

const checkAuth = props => {
  const userToken = localStorage.getItem("userToken");

  if (!userToken) {
    return false;
  }

  try {
    const { exp } = decode(userToken);

    if (exp < Date.now() / 1000) {
      return false;
    }
  } catch (error) {
    return false;
  }

  const url = props.match.url;
  const paths = url.split("/");
  const path = paths.pop();
  if (!hasReadPermission(path)) {
    return false;
  }

  return true;
};

export const AuthRoute = ({ component: Component, ...rest }) => (
  <App>
    <Route
      {...rest}
      render={props =>
        checkAuth(props) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  </App>
);

class Root extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/login" render={props => <LoginPage {...props} />} />
        <Route
          exact
          path="/member-details"
          render={props => <FindMemberPage {...props} />}
        />
        <Route
          exact
          path="/confirm-email"
          render={props => <VerifyUserPage {...props} />}
        />
        <Route
          exact
          path="/forgot-password"
          render={props => <ForgotPasswordPage {...props} />}
        />

        <AuthRoute exact path="/" component={DashboardPage} />
        <AuthRoute path="/dashboard" component={DashboardPage} />
        <AuthRoute path="/members" component={MembersPage} />
        <AuthRoute path="/requests" component={RequestsPage} />
        <AuthRoute path="/groups" component={GroupsPage} />
        <AuthRoute path="/reports" component={ReportsPage} />
        <AuthRoute path="/settings" component={SettingsPage} />
        <AuthRoute path="/users-and-roles" component={UsersAndRolesPage} />

        <AuthRoute path="/import-members" component={ImportMembers} />
        <AuthRoute path="/approve-members" component={ApproveMembers} />

        <AuthRoute path="/report-activity-log" component={ActivityLog} />
        <AuthRoute path="/report-general" component={ReportGeneral} />
        <AuthRoute path="/group-general" component={GroupGeneral} />
        <AuthRoute path="/report-deceased-list" component={DeceasedList} />
        <AuthRoute path="/report-missing-nic" component={MissingNIC} />
      </Switch>
    );
  }
}

export default Root;
