export const isAdmin = () => {
  try {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails !== null) {
      const user = JSON.parse(userDetails);
      const { role } = user;

      if (role.identifier && role.identifier === "admin") return true;
    }
  } catch (error) {
    return false;
  }
  return false;
};

export const hasReadPermission = module => {
  try {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails !== null) {
      const user = JSON.parse(userDetails);
      const { role } = user;

      if (role.identifier && role.identifier === "admin") return true;
      if (module === "" || module === "dashboard") return true;

      // return false otherwise
      return (
        (role.permissions[module] && role.permissions[module]["read"]) || false
      );
    }
  } catch (error) {
    return false;
  }
  return false;
};

export const hasCreatePermission = module => {
  try {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails !== null) {
      const user = JSON.parse(userDetails);
      const { role } = user;

      if (role.identifier && role.identifier === "admin") return true;

      // return false otherwise
      return (
        (role.permissions[module] && role.permissions[module]["create"]) ||
        false
      );
    }
  } catch (error) {
    return false;
  }
  return false;
};

export const hasUpdatePermission = module => {
  try {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails !== null) {
      const user = JSON.parse(userDetails);
      const { role } = user;

      if (role.identifier && role.identifier === "admin") return true;

      // return false otherwise
      return (
        (role.permissions[module] && role.permissions[module]["update"]) ||
        false
      );
    }
  } catch (error) {
    return false;
  }
  return false;
};

export const hasDeletePermission = module => {
  try {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails !== null) {
      const user = JSON.parse(userDetails);
      const { role } = user;

      if (role.identifier && role.identifier === "admin") return true;

      // return false otherwise
      return (
        (role.permissions[module] && role.permissions[module]["delete"]) ||
        false
      );
    }
  } catch (error) {
    return false;
  }
  return false;
};

export const hasMemberApprovePermission = () => {
  try {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails !== null) {
      const user = JSON.parse(userDetails);
      const { role } = user;

      if (role.identifier && role.identifier === "admin") return true;

      // return false otherwise
      return role.permissions["approveRejectMembers"] || false;
    }
  } catch (error) {
    return false;
  }
  return false;
};
