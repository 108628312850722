import React, { Component } from "react";
import { Layout, Menu, Icon, Tag } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import "./App.css";
import { pendingRequestsSelector } from "./redux/selectors";
import {
  fetchMembers,
  fetchRequests,
  fetchUsers,
  fetchRoles,
  fetchReports,
  fetchGroups,
  getSettings
} from "./redux/actions";
import Image from "react-image-resizer";
import { showConfirmLogout } from "./utils/confirmDialogs";
import { hasReadPermission, isAdmin } from "./utils/permissions";

const { Content, Footer, Sider } = Layout;
// const SubMenu = Menu.SubMenu;

class App extends Component {
  constructor() {
    super();
    this.state = {
      collapsed: false,
      notificationsVisible: false,
      isHelpPopoverVisible: false,
      isUserPopoverVisible: false,
      isFeedbackVisible: false,
      auth: undefined
    };
  }

  componentDidMount() {
    const authDetails = localStorage.getItem("userDetails");
    const auth = JSON.parse(authDetails);
    this.setState({ auth });
    if (auth) {
      this.fetchStateFromServer();
    }
  }

  fetchStateFromServer = () => {
    this.props.getSettings();
    this.props.fetchMembers();
    this.props.fetchRequests();
    this.props.fetchUsers();
    this.props.fetchRoles();
    this.props.fetchReports();
    this.props.fetchGroups();
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  signOut = () => {
    showConfirmLogout(this.onConfirmSignOut);
  };

  onConfirmSignOut = () => {
    localStorage.clear();
    this.props.history.push("/login");
    window.location.reload(true);
  };

  render() {
    const { history, children, pendingRequests } = this.props;
    const { pathname } = this.props.location;

    return (
      <div>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            style={{
              overflow: "auto",
              height: `calc(100% - 48px)`,
              position: "fixed",
              left: 0,
              background: "#242A45"
            }}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            {!this.state.collapsed && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Image
                  img
                  src={`assets/images/logo.png`}
                  alt=""
                  height={150}
                  width={180}
                  style={{ alignSelf: "center" }}
                />
              </div>
            )}

            <Menu
              style={{ background: "#242A45" }}
              theme="dark"
              defaultSelectedKeys={[pathname]}
              mode="inline"
            >
              <Menu.Item
                key="/dashboard"
                onClick={() => history.push("/dashboard")}
              >
                <Icon type="dashboard" />
                <span>Dashboard</span>
              </Menu.Item>

              {hasReadPermission("members") && (
                <Menu.Item
                  key="/members"
                  onClick={() => history.push("/members")}
                >
                  <Icon type="user" />
                  <span>Members</span>
                </Menu.Item>
              )}

              {hasReadPermission("requests") && (
                <Menu.Item
                  key="/requests"
                  onClick={() => history.push("/requests")}
                >
                  <Icon type="pull-request" />
                  <span>
                    Requests
                    {pendingRequests.length > 0 && (
                      <Tag style={{ marginLeft: 16 }} color="#f50">
                        NEW
                      </Tag>
                    )}
                  </span>
                </Menu.Item>
              )}

              {hasReadPermission("groups") && (
                <Menu.Item
                  key="/groups"
                  onClick={() => history.push("/groups")}
                >
                  <Icon type="team" />
                  <span>Groups</span>
                </Menu.Item>
              )}

              {hasReadPermission("reports") && (
                <Menu.Item
                  key="/reports"
                  onClick={() => history.push("/reports")}
                >
                  <Icon type="reconciliation" />
                  <span>Reports</span>
                </Menu.Item>
              )}

              {isAdmin() && (
                <Menu.Item
                  key="/settings"
                  onClick={() => history.push("/settings")}
                >
                  <Icon type="setting" />
                  <span>Settings</span>
                </Menu.Item>
              )}

              <div style={{ margin: "32px 0" }} />

              <Menu.Item key="/logout" onClick={this.signOut}>
                <Icon type="logout" />
                <span>Logout</span>
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout
            style={{
              marginLeft: this.state.collapsed ? 80 : 200,
              transition: "all 0.01s ease-in",
              background: "#FAFAFA"
            }}
          >
            <Content style={{ margin: "8px 8px" }}>
              <div
                style={{
                  padding: pathname !== "/settings" ? 16 : 0,
                  background:
                    pathname !== "/dashboard" &&
                    pathname !== "/" &&
                    pathname !== "/settings"
                      ? "#fff"
                      : "",
                  minHeight: 660,
                  borderRadius: 4
                }}
              >
                {children}
              </div>
            </Content>

            <Footer style={{ textAlign: "center", background: "#FAFAFA" }}>
              CODE[369] © 2019
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  pendingRequestsSelector,
  pendingRequests => ({
    pendingRequests
  })
);

const mapDispatchToProps = {
  fetchMembers,
  fetchRequests,
  fetchUsers,
  fetchRoles,
  fetchReports,
  fetchGroups,
  getSettings
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
