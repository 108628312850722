import React, { Component } from "react";
import { Tabs } from "antd";
import ManageUsers from "../components/ManageUsers";
import ManageRoles from "../components/ManageRoles";

const { TabPane } = Tabs;

class UsersAndRolesPage extends Component {
  render() {
    return (
      <div>
        <Tabs>
          <TabPane tab="Users" key="1">
            <ManageUsers />
          </TabPane>
          <TabPane tab="Roles" key="2">
            <ManageRoles />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default UsersAndRolesPage;
