import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* fetchRoles() {
  try {
    const roles = yield call(sendRequest, "roles", "GET");
    yield put({ type: types.FETCH_ROLES_SUCCESS, payload: roles.data });
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* createRole({ payload }) {
  try {
    const response = yield call(sendRequest, "roles", "POST", payload);
    yield put({ type: types.FETCH_ROLES });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* updateRole({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `roles/${payload._id}`,
      "PUT",
      payload
    );
    yield put({ type: types.FETCH_ROLES });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* deleteRole({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `roles/${payload._id}`,
      "DELETE",
      payload
    );
    yield put({ type: types.FETCH_ROLES });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* rolesSagaWatcher() {
  yield takeLatest(types.FETCH_ROLES, fetchRoles);
  yield takeEvery(types.CREATE_ROLE, createRole);
  yield takeEvery(types.UPDATE_ROLE, updateRole);
  yield takeEvery(types.DELETE_ROLE, deleteRole);
}
