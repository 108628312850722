import React, { Component } from "react";
import { Dropdown, Menu, Checkbox, Pagination, Icon, Input } from "antd";
import { formatMoney } from "../../utils/formatters";
import LazyLoad from "react-lazyload";

class Table extends Component {
  constructor() {
    super();
    this.state = {
      searchQuery: "",
      currentPage: 1,
      pageSize: 10
    };
  }

  filterData(query) {
    const { dataSource } = this.props;

    if (query === "") {
      return dataSource;
    }
    const regex = new RegExp(
      `${query.toString().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}`,
      "i"
    );
    return dataSource.filter(t => {
      for (let key in t) {
        if (typeof t[key] === "string" && t[key].search(regex) >= 0) {
          return true;
        }
      }
      return false;
    });
  }

  getValue = (row, key, render = (t, r) => t, money) => {
    let keys = key.split(".");
    let val = Object.assign({}, row);
    keys.forEach(k => {
      val = val[k] || (val[k] === 0 && "0") || "";
    });
    if (money) {
      return formatMoney(val);
    }
    return render(val, row);
  };

  getColumnTotal = (dataSource, key, money) => {
    let total = dataSource.reduce((prev, next) => prev + next[key], 0);

    if (money) {
      return formatMoney(total);
    }
    return total;
  };

  handleClick = (e, row) => {
    this.props.onActionClick(e, row);
  };

  onPageChange = page => {
    this.setState({
      currentPage: page
    });
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState({ currentPage: current, pageSize });
  };

  getDataSource = filteredData => {
    const { pagination } = this.props;
    const { currentPage, pageSize } = this.state;

    if (!pagination) {
      return filteredData;
    }

    let start = (currentPage - 1) * pageSize;
    let finish = currentPage * pageSize;

    let rows =
      (filteredData.length > start && filteredData.slice(start, finish)) ||
      filteredData.slice(0, pageSize);

    return rows;
  };

  onCheckAll = e => {
    const { onSelectChange, dataSource } = this.props;

    // const filteredData = this.filterData(this.state.searchQuery);
    // const dataSource = this.getDataSource(filteredData);

    onSelectChange(e.target.checked, dataSource);
  };

  render() {
    const {
      columns,
      actions,
      selectable,
      pagination,
      showTotal,
      selectedItems,
      hideSearch,
      showSelectedCount
    } = this.props;
    const filteredData = this.filterData(this.state.searchQuery);
    const dataSource = this.getDataSource(filteredData);

    return (
      <div>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            {!hideSearch && (
              <Input.Search
                placeholder="Search"
                onChange={e => this.setState({ searchQuery: e.target.value })}
                style={{ width: 200 }}
              />
            )}
            {showSelectedCount && selectedItems.length > 0 && (
              <span style={{ marginLeft: 16, fontSize: 18, fontWeight: 400 }}>
                {selectedItems.length} selected
              </span>
            )}
          </div>
        </div>
        <div style={{ overflowX: "auto", borderRadius: 8 }}>
          <table style={{ width: `calc(100%)` }}>
            <thead>
              <tr>
                {selectable && (
                  <th
                    style={{
                      width: "40px",
                      textAlign: "center",
                      background: "#FBFBFB",
                      borderBottom: "1px solid #eee"
                    }}
                  >
                    <Checkbox
                      indeterminate={
                        selectedItems &&
                        selectedItems.length > 0 &&
                        selectedItems.length < this.props.dataSource.length
                      }
                      checked={
                        selectedItems &&
                        selectedItems.length === this.props.dataSource.length &&
                        this.props.dataSource.length > 0
                      }
                      disabled={this.props.dataSource.length === 0}
                      onChange={this.onCheckAll}
                    />
                  </th>
                )}
                {actions && (
                  <th
                    style={{
                      width: "40px",
                      background: "#FBFBFB",
                      borderBottom: "1px solid #eee"
                    }}
                  />
                )}
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className="table-highlight"
                    style={{
                      textAlign: col.align,
                      height: 40,
                      minWidth: col.width ? col.width : 80,
                      padding: "0px 8px",
                      background: "#FBFBFB",
                      fontWeight: "bold",
                      borderBottom: "1px solid #eee"
                    }}
                  >
                    {col.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataSource.map((row, rowIndex) => (
                <LazyLoad
                  key={rowIndex}
                  once={true}
                  offset={100}
                  placeholder={<tr></tr>}
                >
                  <tr className="table-highlight" key={rowIndex}>
                    {selectable && (
                      <td
                        style={{
                          width: "40px",
                          textAlign: "center",
                          padding: "0px 8px",
                          borderBottom: "1px solid #eee"
                        }}
                      >
                        <Checkbox
                          checked={
                            (selectedItems &&
                              selectedItems.some(t => t._id === row._id)) ||
                            false
                          }
                          onChange={e =>
                            this.props.onSelectChange
                              ? this.props.onSelectChange(e.target.checked, row)
                              : null
                          }
                        />
                      </td>
                    )}
                    {actions && (
                      <td
                        style={{
                          width: "40px",
                          textAlign: "center",
                          padding: "0px 8px",
                          borderBottom: "1px solid #eee"
                        }}
                      >
                        <Dropdown
                          overlay={
                            <Menu onClick={e => this.handleClick(e, row)}>
                              {actions &&
                                actions.map((item, index) => (
                                  <Menu.Item key={item.key}>
                                    {item.label}
                                  </Menu.Item>
                                ))}
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          {/* <Button type="danger" shape="circle" icon="down" /> */}
                          <Icon
                            type="down-circle"
                            theme="outlined"
                            style={{ fontSize: "20px" }} /* "#1A90FD" */
                          />
                        </Dropdown>
                      </td>
                    )}
                    {columns.map((col, colIndex) => (
                      <td
                        className="test1"
                        key={colIndex}
                        style={{
                          textAlign: col.align,
                          height: 40,
                          minWidth: col.width || 120,
                          whiteSpace: "nowrap",
                          padding: "0px 8px",
                          borderBottom: "1px solid #eee"
                        }}
                        onClick={() =>
                          this.props.onClick ? this.props.onClick(row) : null
                        }
                      >
                        {this.getValue(row, col.key, col.render, col.money)}
                      </td>
                    ))}
                  </tr>
                </LazyLoad>
              ))}
              {showTotal && (
                <tr>
                  {selectable && (
                    <th
                      style={{
                        width: "40px",
                        background: "#FBFBFB",
                        borderBottom: "1px solid #eee"
                      }}
                    />
                  )}
                  {actions && (
                    <td
                      style={{
                        width: "40px",
                        textAlign: "center",
                        padding: "0px 8px",
                        borderBottom: "1px solid #eee"
                      }}
                    />
                  )}
                  {columns.map((col, index) => (
                    <td
                      key={index}
                      style={{
                        textAlign: col.align,
                        height: 40,
                        minWidth: col.width ? col.width : 120,
                        padding: "0px 8px",
                        borderBottom: "1px solid #eee",
                        fontWeight: "bold"
                      }}
                    >
                      {col.total &&
                        this.getColumnTotal(dataSource, col.key, col.money)}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pagination && this.props.dataSource.length > this.state.pageSize && (
          <div style={{ margin: "8px 0", textAlign: "right" }}>
            <Pagination
              size="small"
              current={this.state.currentPage}
              onChange={this.onPageChange}
              total={this.props.dataSource.length}
              showSizeChanger
              onShowSizeChange={this.onShowSizeChange}
            />
          </div>
        )}
      </div>
    );
  }
}

export { Table };
