import * as types from "./types";

export function fetchGroups() {
  return {
    type: types.FETCH_GROUPS
  };
}

export function createGroup(payload) {
  return {
    type: types.CREATE_GROUP,
    payload
  };
}

export function updateGroup(payload) {
  return {
    type: types.UPDATE_GROUP,
    payload
  };
}

export function deleteGroup(payload) {
  return {
    type: types.DELETE_GROUP,
    payload
  };
}
