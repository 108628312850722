import React, { Component } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Input, Select } from "../../utils/formComponents";
import { required, email } from "../../utils/validators";
import { rolesSelector } from "../../redux/selectors";
import { showConfirmMakeAdmin } from "../../utils/confirmDialogs";

const FormInput = props => (
  <div style={{ marginBottom: 8 }}>
    <span className="bold-text">{props.label}</span>
    <Field name={props.name} component={Input} {...props} />
  </div>
);

const FormSelect = props => (
  <div style={{ marginBottom: 8 }}>
    <span className="bold-text">{props.label}</span>
    <Field
      name={props.name}
      style={{ width: "100%" }}
      component={Select}
      {...props}
    />
  </div>
);

class UserForm extends Component {
  confirmSubmition = () => {
    const { selectedRole, roles } = this.props;
    const user = this.props.initialValues;
    const role = roles && roles.find(t => t._id === selectedRole);
    const userRole = roles && roles.find(t => t._id === user.role);

    if (
      userRole &&
      userRole.identifier !== "admin" &&
      role.identifier === "admin" &&
      !this.props.newItem
    ) {
      showConfirmMakeAdmin(this.props.handleSubmit);
    } else {
      this.props.handleSubmit();
    }
  };

  canDeleteUser = () => {
    const { roles } = this.props;
    const user = this.props.initialValues;
    const role = roles && roles.find(t => t._id === user.role);

    const userDetails = localStorage.getItem("userDetails");
    const auth = JSON.parse(userDetails);

    if (
      (user._id !== auth._id && role && role.identifier === "admin") ||
      this.props.newItem
    ) {
      return false;
    }

    return true;
  };

  isAdmin = () => {
    const { roles } = this.props;
    const user = this.props.initialValues;
    const role = roles && roles.find(t => t._id === user.role);

    if (role && role.identifier === "admin") {
      return true;
    }

    return false;
  };

  render() {
    const { handleSubmit, roles } = this.props;
    const user = this.props.initialValues;

    const roleOptions = roles.map(t => ({
      label: t.name,
      value: t._id
    }));

    return (
      <form onSubmit={handleSubmit}>
        <h1>{(this.props.newItem && "Create User") || "Update User"}</h1>

        <FormInput label="Username" name="username" validate={[required]} />

        <FormInput label="Email" name="email" validate={[required, email]} />

        {this.props.newItem && (
          <FormInput label="Password" name="password" validate={[required]} />
        )}

        <FormSelect
          label="Role"
          name="role"
          options={roleOptions}
          validate={[required]}
          disabled={this.isAdmin()}
        />

        <div
          style={{
            width: "100%",
            padding: "10px 0px",
            background: "#fff",
            textAlign: "right",
            marginTop: 32,
            marginBottom: -16
          }}
        >
          <Button
            style={{ marginRight: 8, width: 100 }}
            onClick={this.props.onClose}
          >
            Cancel
          </Button>

          {this.canDeleteUser() && (
            <Button
              style={{ marginRight: 8, width: 100 }}
              onClick={() => this.props.onDelete(user)}
            >
              Delete
            </Button>
          )}

          <Button
            style={{ width: 100 }}
            onClick={this.confirmSubmition}
            type="primary"
          >
            {(this.props.newItem && "Save") || "Update"}
          </Button>
        </div>
      </form>
    );
  }
}

UserForm = reduxForm({
  form: "user-form"
})(UserForm);

const selector = formValueSelector("user-form");

const mapStateToProps = createSelector(
  rolesSelector,
  state => selector(state, "role"),
  (roles, selectedRole) => ({
    roles,
    selectedRole
  })
);

export default connect(mapStateToProps)(UserForm);
