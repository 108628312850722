import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { verifyEmail } from "../redux/actions";
import queryString from "query-string";
import { createSelector } from "reselect";
import {
  isVerifyingEmailSelector,
  emailVerifiedSelector
} from "../redux/selectors";
import { Icon, Button } from "antd";

class VerifyUserPage extends Component {
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);

    if (params.uid && params.token) {
      this.props.verifyEmail(params);
    } else {
      window.location.href = "/login";
    }
  }

  onLogin = () => {
    window.location.href = "/login";
  };

  render() {
    const { isVerifyingEmail, emailVerified } = this.props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "linear-gradient(to right bottom, #1B38A8, #1976D2)",
          // backgroundImage: "url(assets/imgs/login_4.gif)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed"
        }}
      >
        <div
          style={{
            justifyContent: "center",
            width: 480,
            padding: 16,
            background: "white",
            borderRadius: 8
          }}
        >
          {(isVerifyingEmail && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: 28,
                fontWeight: "bold"
              }}
            >
              <Icon type="loading" />
            </div>
          )) || (
            <Fragment>
              {emailVerified === true && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 28,
                    fontWeight: "bold"
                  }}
                >
                  <div>Email Verification Successful</div>
                  <Button
                    type="danger"
                    style={{ width: 160 }}
                    onClick={this.onLogin}
                  >
                    LOGIN
                  </Button>
                </div>
              )}
              {emailVerified === false && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 28,
                    fontWeight: "bold"
                  }}
                >
                  <div>Email Verification Failed</div>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isVerifyingEmailSelector,
  emailVerifiedSelector,
  (isVerifyingEmail, emailVerified) => ({
    isVerifyingEmail,
    emailVerified
  })
);

const mapDispatchToProps = dispatch => {
  return {
    verifyEmail: nic => dispatch(verifyEmail(nic))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyUserPage);
