import React from "react";
import { Checkbox } from "antd";

export const renderCheckbox = props => {
  const {
    input: { value, onChange }
  } = props;
  return (
    <Checkbox
      {...props}
      onChange={onChange}
      checked={value === "" ? false : value}
    >
      {props.hideLabel === undefined && props.label}
    </Checkbox>
  );
};
