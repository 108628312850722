// members
export const FETCH_MEMBERS = "$MEMBERS:FETCH_MEMBERS";
export const CREATE_MEMBER = "$MEMBERS:CREATE_MEMBER";
export const UPDATE_MEMBER = "$MEMBERS:UPDATE_MEMBER";
export const DELETE_MEMBER = "$MEMBERS:DELETE_MEMBER";
export const IMPORT_MEMBERS = "$MEMBERS:IMPORT_MEMBERS";
export const DELETE_MANY_MEMBERS = "$MEMBERS:DELETE_MANY_MEMBERS";
export const APPROVE_MEMBERS = "$MEMBERS:APPROVE_MEMBERS";
export const REJECT_MEMBERS = "$MEMBERS:REJECT_MEMBERS";

export const FETCH_MEMBERS_SUCCESS = "$MEMBERS:FETCH_MEMBERS_SUCCESS";
export const CREATE_MEMBER_SUCCESS = "$MEMBERS:CREATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_SUCCESS = "$MEMBERS:UPDATE_MEMBER_SUCCESS";
export const DELETE_MEMBER_SUCCESS = "$MEMBERS:DELETE_MEMBER_SUCCESS";
export const IMPORT_MEMBERS_SUCCESS = "$MEMBERS:IMPORT_MEMBERS_SUCCESS";
export const DELETE_MANY_MEMBERS_SUCCESS =
  "$MEMBERS:DELETE_MANY_MEMBERS_SUCCESS";
export const APPROVE_MEMBERS_SUCCESS = "$MEMBERS:APPROVE_MEMBERS_SUCCESS";
export const REJECT_MEMBERS_SUCCESS = "$MEMBERS:REJECT_MEMBERS_SUCCESS";

export const APPROVE_MEMBERS_FAILED = "$MEMBERS:APPROVE_MEMBERS_FAILED";

// auth
export const AUTHENTICATE = "$AUTH:AUTHENTICATE";
export const AUTHENTICATE_SUCCESS = "$AUTH:AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_FAILED = "$AUTH:AUTHENTICATE_FAILED";

export const FIND_MEMBER_BY_NIC = "$MEMBERS:FIND_MEMBER_BY_NIC";
export const FIND_MEMBER_BY_NIC_SUCCESS = "$MEMBERS:FIND_MEMBER_BY_NIC_SUCCESS";
export const FIND_MEMBER_BY_NIC_FAILED = "$MEMBERS:FIND_MEMBER_BY_NIC_FAILED";

export const VERIFY_EMAIL = "$AUTH:VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "$AUTH:VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILED = "$AUTH:VERIFY_EMAIL_FAILED";

export const FORGOT_PASSWORD = "$AUTH:FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "$AUTH:FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "$AUTH:FORGOT_PASSWORD_FAILED";

// requests
export const FETCH_REQUESTS = "$REQUESTS:FETCH_REQUESTS";
export const CREATE_REQUEST = "$REQUESTS:CREATE_REQUEST";
export const UPDATE_REQUEST = "$REQUESTS:UPDATE_REQUEST";
export const DELETE_REQUEST = "$REQUESTS:DELETE_REQUEST";
export const DELETE_MANY_REQUESTS = "$REQUESTS:DELETE_MANY_REQUESTS";
export const APPROVE_REQUEST = "$REQUESTS:APPROVE_REQUEST";
export const REJECT_REQUEST = "$REQUESTS:REJECT_REQUEST";

export const FETCH_REQUESTS_SUCCESS = "$REQUESTS:FETCH_REQUESTS_SUCCESS";
export const CREATE_REQUEST_SUCCESS = "$REQUESTS:CREATE_REQUEST_SUCCESS";
export const UPDATE_REQUEST_SUCCESS = "$REQUESTS:UPDATE_REQUEST_SUCCESS";
export const DELETE_REQUEST_SUCCESS = "$REQUESTS:DELETE_REQUEST_SUCCESS";
export const DELETE_MANY_REQUESTS_SUCCESS =
  "$REQUESTS:DELETE_MANY_REQUESTS_SUCCESS";
export const APPROVE_REQUEST_SUCCESS = "$REQUESTS:APPROVE_REQUEST_SUCCESS";
export const REJECT_REQUEST_SUCCESS = "$REQUESTS:REJECT_REQUEST_SUCCESS";

// reports
export const FETCH_REPORTS = "$REPORTS:FETCH_REPORTS";
export const CREATE_REPORT = "$REPORTS:CREATE_REPORT";
export const UPDATE_REPORT = "$REPORTS:UPDATE_REPORT";
export const DELETE_REPORT = "$REPORTS:DELETE_REPORT";
export const IMPORT_REPORTS = "$REPORTS:IMPORT_REPORTS";

export const FETCH_REPORTS_SUCCESS = "$REPORTS:FETCH_REPORTS_SUCCESS";
export const CREATE_REPORT_SUCCESS = "$REPORTS:CREATE_REPORT_SUCCESS";
export const UPDATE_REPORT_SUCCESS = "$REPORTS:UPDATE_REPORT_SUCCESS";
export const DELETE_REPORT_SUCCESS = "$REPORTS:DELETE_REPORT_SUCCESS";
export const IMPORT_REPORTS_SUCCESS = "$REPORTS:IMPORT_REPORTS_SUCCESS";

// groups
export const FETCH_GROUPS = "$GROUPS:FETCH_GROUPS";
export const CREATE_GROUP = "$GROUPS:CREATE_GROUP";
export const UPDATE_GROUP = "$GROUPS:UPDATE_GROUP";
export const DELETE_GROUP = "$GROUPS:DELETE_GROUP";

export const FETCH_GROUPS_SUCCESS = "$GROUPS:FETCH_GROUPS_SUCCESS";
export const CREATE_GROUP_SUCCESS = "$GROUPS:CREATE_GROUP_SUCCESS";
export const UPDATE_GROUP_SUCCESS = "$GROUPS:UPDATE_GROUP_SUCCESS";
export const DELETE_GROUP_SUCCESS = "$GROUPS:DELETE_GROUP_SUCCESS";

// settings
export const GET_SETTINGS = "$SETTINGS:GET_SETTINGS";
export const SET_SETTINGS = "$SETTINGS:SET_SETTINGS";

export const GET_SETTINGS_SUCCESS = "$SETTINGS:GET_SETTINGS_SUCCESS";
export const SET_SETTINGS_SUCCESS = "$SETTINGS:SET_SETTINGS_SUCCESS";

// users
export const FETCH_USERS = "$USERS:FETCH_USERS";
export const CREATE_USER = "$USERS:CREATE_USER";
export const UPDATE_USER = "$USERS:UPDATE_USER";
export const DELETE_USER = "$USERS:DELETE_USER";
export const IMPORT_USERS = "$USERS:IMPORT_USERS";

export const FETCH_USERS_SUCCESS = "$USERS:FETCH_USERS_SUCCESS";
export const CREATE_USER_SUCCESS = "$USERS:CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "$USERS:UPDATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "$USERS:DELETE_USER_SUCCESS";
export const IMPORT_USERS_SUCCESS = "$USERS:IMPORT_USERS_SUCCESS";

// roles
export const FETCH_ROLES = "$ROLES:FETCH_ROLES";
export const CREATE_ROLE = "$ROLES:CREATE_ROLE";
export const UPDATE_ROLE = "$ROLES:UPDATE_ROLE";
export const DELETE_ROLE = "$ROLES:DELETE_ROLE";
export const IMPORT_ROLES = "$ROLES:IMPORT_ROLES";

export const FETCH_ROLES_SUCCESS = "$ROLES:FETCH_ROLES_SUCCESS";
export const CREATE_ROLE_SUCCESS = "$ROLES:CREATE_ROLE_SUCCESS";
export const UPDATE_ROLE_SUCCESS = "$ROLES:UPDATE_ROLE_SUCCESS";
export const DELETE_ROLE_SUCCESS = "$ROLES:DELETE_ROLE_SUCCESS";
export const IMPORT_ROLES_SUCCESS = "$ROLES:IMPORT_ROLES_SUCCESS";

// actions
export const ACTION_SEND_EMAIL = "$ACTION:ACTION_SEND_EMAIL";

// activity logs
export const FETCH_ACTIVITY_LOG = "$ACTIVITY_LOG:FETCH_ACTIVITY_LOG";
export const FETCH_ACTIVITY_LOG_SUCCESS =
  "$ACTIVITY_LOG:FETCH_ACTIVITY_LOG_SUCCESS";
