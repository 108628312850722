import { createSelector } from "reselect";

export const isLoadingMembers = createSelector(
  state => state.members.isLoading,
  isLoading => isLoading
);

export const membersSelector = createSelector(
  state => state.members.members,
  members => selectMembers(members)
);

export const approvedMembersSelector = createSelector(
  state => state.members.members,
  members => selectApprovedMembers(members)
);

export const unapprovedMembersSelector = createSelector(
  state => state.members.members,
  members => selectUnapprovedMembers(members)
);

export const isLoadingMemberDetails = createSelector(
  state => state.members.isLoadingMemberDetails,
  isLoading => isLoading
);

export const memberDetailsSelector = createSelector(
  state => state.members.memberDetails,
  member => member
);

export const isApprovingMembersSelector = createSelector(
  state => state.members.isApprovingMembers,
  isApprovingMembers => isApprovingMembers
);

function selectMembers(members) {
  return members.sort((a, b) =>
    a.Surname > b.Surname ? 1 : b.Surname > a.Surname ? -1 : 0
  );
}

function selectApprovedMembers(members) {
  const approvedembers = members.filter(t => t.approved);
  return approvedembers.sort((a, b) =>
    a.Surname > b.Surname ? 1 : b.Surname > a.Surname ? -1 : 0
  );
}

function selectUnapprovedMembers(members) {
  const unapprovedembers = members.filter(t => !t.approved);
  return unapprovedembers.sort((a, b) =>
    a.Surname > b.Surname ? 1 : b.Surname > a.Surname ? -1 : 0
  );
}
