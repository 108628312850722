import * as types from "../actions/types";

let initialState = {
  isLoading: false,
  requests: []
};

export function requestsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_REQUESTS:
      return { ...state, isLoading: true };
    case types.FETCH_REQUESTS_SUCCESS:
      return { ...state, requests: payload, isLoading: false };
    case types.CREATE_REQUEST_SUCCESS:
      return { ...state, requests: [...state.requests, payload.newItem] };
    case types.UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        requests: state.requests.map(item => {
          if (item._id !== payload._id) {
            return item;
          }
          return {
            ...item,
            ...payload.item
          };
        })
      };
    case types.DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        requests: state.requests.filter(item => item._id !== payload._id)
      };

    default:
      return state;
  }
}
