import { Modal } from "antd";

const confirm = Modal.confirm;

export const showConfirmDeleteMember = ok => {
  confirm({
    title: "Delete Member?",
    content: "Are you sure you want to delete this member?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};

export const showConfirmDeleteManyMembers = ok => {
  confirm({
    title: "Delete Members?",
    content: "Are you sure you want to delete selected members?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};

export const showConfirmDeleteRole = ok => {
  confirm({
    title: "Delete Role?",
    content: "Are you sure you want to this role?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};

export const showConfirmDeleteUser = ok => {
  confirm({
    title: "Delete User?",
    content: "Are you sure you want to this user?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};

export const showConfirmMakeAdmin = ok => {
  confirm({
    title: "Are you sure?",
    content:
      "Are you sure you want to make this user an admin? This action cannot be undone later.",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};

export const showConfirmLogout = ok => {
  confirm({
    title: "Are You Sure?",
    content: "Are you sure you want to logout?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};

export const showConfirmRejectMember = ok => {
  confirm({
    title: "Are You Sure?",
    content: "Are you sure you want to reject?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};

export const showConfirmDeleteReport = ok => {
  confirm({
    title: "Are You Sure?",
    content: "Are you sure you want to delete this report?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};

export const showConfirmDeleteGroup = ok => {
  confirm({
    title: "Are You Sure?",
    content: "Are you sure you want to delete this group?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      ok();
    },
    onCancel() {}
  });
};
