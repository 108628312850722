import { createSelector } from "reselect";

export const isLoadingActivityLog = createSelector(
  state => state.activityLog.isLoading,
  isLoading => isLoading
);

export const activityLogSelector = createSelector(
  state => state.activityLog.activityLog,
  activityLog => selectActivityLog(activityLog)
);

function selectActivityLog(activityLog) {
  return activityLog.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
  );
}
