import React, { Component } from "react";
import { Row, Col } from "antd";
import Clock from "react-live-clock";
import TotalMembersCard from "../dashboard/TotalMembersCard";
import UpdateRequestsCard from "../dashboard/UpdateRequestsCard";
import JoinedDateCard from "../dashboard/JoinedDateCard";
import JoinedThisMonthCard from "../dashboard/JoinedThisMonthCard";
import JoinedThisYearCard from "../dashboard/JoinedThisYearCard";
import YearVsMembersLineCard from "../dashboard/YearVsMembersLineCard";
import RecentlyJoinedMembers from "../dashboard/RecentlyJoinedMembers";

class DashboardPage extends Component {
  getUsername = () => {
    const authDetails = localStorage.getItem("userDetails");
    const auth = JSON.parse(authDetails);
    const name = auth && auth.username;
    const role = auth && auth.role && auth.role.name;

    return `Hello ${name} (${role})`;
  };
  render() {
    return (
      <div>
        <div style={{ display: "flex", marginBottom: 16 }}>
          <div style={{ flex: 2, textAlign: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 28 }}>
              {this.getUsername()}
            </span>
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <Clock
              style={{ fontSize: "1.2em" }}
              format="dddd, MMMM DD, YYYY, h:mm:ss A"
              ticking={true}
              interval={1000}
            />
          </div>
        </div>

        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={6}>
            <TotalMembersCard />
          </Col>
          <Col span={6}>
            <JoinedThisMonthCard />
          </Col>
          <Col span={6}>
            <JoinedThisYearCard />
          </Col>
          <Col span={6}>
            <UpdateRequestsCard />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <JoinedDateCard />
          </Col>
          <Col span={12}>
            <YearVsMembersLineCard />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <RecentlyJoinedMembers />
          </Col>
        </Row>
      </div>
    );
  }
}

export default DashboardPage;
