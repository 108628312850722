import React, { Component } from "react";
import {
  Steps,
  Button,
  Icon,
  Upload,
  Select,
  Row,
  Col,
  Divider,
  Spin
} from "antd";
import XLSX from "xlsx";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { membersSchemaSelector } from "../redux/selectors";
import { importMembers } from "../redux/actions";
import { Table } from "./table";
import moment from "moment";

import { loadingIcon } from "../utils/loadingIcon";

const Step = Steps.Step;
const Dragger = Upload.Dragger;
const Option = Select.Option;

class ImportMembers extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      currentStep: 0,
      headers: [],
      data: [],
      requiredHeaders: undefined,
      columns: [],
      dataSource: []
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.schema &&
      (prevProps.schema !== this.props.schema ||
        this.state.requiredHeaders === undefined)
    ) {
      let requiredHeaders = {};
      this.props.schema.forEach(t => {
        requiredHeaders[t.name] = { label: t.name, value: null, type: t.type };
      });
      this.setState({ requiredHeaders });
    }
  }

  next() {
    const currentStep = this.state.currentStep + 1;
    this.setState({ currentStep });
  }

  prev() {
    const currentStep = this.state.currentStep - 1;
    this.setState({ currentStep });
  }

  parseFile = file => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = evt => {
          const bstr = evt.target.result;
          const workbook = XLSX.read(bstr, {
            type: "binary",
            cellDates: true,
            cellNF: false,
            cellText: false
          });
          const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
          resolve(data);
        };
        reader.readAsBinaryString(file);
      } catch (err) {
        reject(err);
      }
    });
  };

  readExcel = async file => {
    this.setState({ isLoading: true });

    const rows = await this.parseFile(file);
    console.log(rows);
    let headers = rows[0];
    let data = rows.slice(1);
    this.setState({ headers: headers, data: data });
    this.setState({ isLoading: false });

    this.setState({ currentStep: this.state.currentStep + 1 });

    // readXlsxFile(file)
    //   .then(rows => {
    //     let headers = rows[0];
    //     let data = rows.slice(1);
    //     this.setState({ headers: headers, data: data });
    //     this.setState({ isLoading: false });
    //   })
    //   .finally(() => {
    //     this.setState({ currentStep: this.state.currentStep + 1 });
    //   });
  };

  mapIndex(value, key) {
    const headers = Object.assign({}, this.state.requiredHeaders);
    headers[key].value = value;
    this.setState({ requiredHeaders: headers });
  }

  showPreview() {
    const { data, requiredHeaders } = this.state;
    let keys = Object.keys(requiredHeaders);
    let dataSource = [];
    data.forEach(d => {
      let a = {};
      keys.forEach(key => {
        a[key] =
          requiredHeaders[key].value !== null
            ? requiredHeaders[key].type === "Date"
              ? // ? moment(d[requiredHeaders[key].value], "DD.MM.YYYY").toDate()
                new Date(d[requiredHeaders[key].value])
              : d[requiredHeaders[key].value]
            : "";
      });
      dataSource.push({ ...a });
    });
    let columns = keys.map(key => ({
      title: requiredHeaders[key].label,
      key: key,
      render:
        (requiredHeaders[key].type === "Date" &&
          (t => moment(t).format("DD MMM YYYY"))) ||
        (t => t)
    }));

    // set approvedAt = Joined Date
    dataSource = dataSource.map(t => ({
      ...t,
      approved: true
      // approvedAt: t["Joined Date"],
    }));

    this.setState({
      currentStep: this.state.currentStep + 1,
      columns: columns,
      dataSource: dataSource
    });
  }

  importData() {
    const { dataSource } = this.state;
    // console.log(dataSource);
    this.props.importMembers(dataSource);
    this.props.history.goBack();
  }

  render() {
    const {
      currentStep,
      headers,
      requiredHeaders,
      columns,
      dataSource,
      isLoading
    } = this.state;

    let options = headers.map((item, index) => (
      <Option key={index} value={index}>
        {item}
      </Option>
    ));

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <Button
              icon="arrow-left"
              onClick={() => this.props.history.goBack()}
            />
            <Divider type="vertical" />
            <span style={{ fontWeight: 600, fontSize: 24 }}>Members</span>
            <Divider type="vertical" />
            <span style={{ fontWeight: 400, fontSize: 20 }}>Import</span>
          </div>

          <div style={{ flex: 1, textAlign: "right" }} />
        </div>

        <Steps
          progressDot
          current={currentStep}
          style={{ marginTop: 24, marginBottom: 16 }}
        >
          <Step title="Upload" />
          <Step title="Map" />
          <Step title="Import" />
        </Steps>

        <div>
          {currentStep === 0 && (
            <div>
              <Dragger
                accept=".xlsx, .xls .csv"
                multiple={false}
                showUploadList={false}
                action={file => this.readExcel(file)}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </div>
          )}
          {currentStep === 1 && (
            <div>
              {Object.keys(requiredHeaders).map((item, index) => (
                <Row key={index} gutter={16} style={{ padding: 4 }}>
                  <Col span={4}>{requiredHeaders[item].label}</Col>
                  <Col span={20}>
                    <Select
                      style={{ width: 200 }}
                      onChange={e => this.mapIndex(e, item)}
                    >
                      {options}
                    </Select>
                  </Col>
                </Row>
              ))}
              <div style={{ marginTop: 16 }}>
                <Button onClick={() => this.prev()}>Go Back</Button>
                <Button
                  style={{ marginLeft: 8 }}
                  onClick={() => this.showPreview()}
                >
                  Show Preview
                </Button>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <Table
                rowKey={r => r.code}
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={{ position: "bottom" }}
                bordered={true}
                scroll={{ x: 1280 }}
              />
              <div style={{ marginTop: 16 }}>
                <Button onClick={() => this.prev()}>Go Back</Button>
                <Button
                  style={{ marginLeft: 8 }}
                  onClick={() => this.importData()}
                  type="primary"
                >
                  Import Data
                </Button>
              </div>
            </div>
          )}
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  membersSchemaSelector,
  schema => ({
    schema
  })
);

const mapDispatchToProps = {
  importMembers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportMembers);
