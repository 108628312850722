import React, { Component } from "react";
import { Drawer } from "antd";
import MemberForm from "./MemberForm";
import { connect } from "react-redux";
import { createMember, updateMember, createRequest } from "../../redux/actions";

class Member extends Component {
  constructor() {
    super();
    this.state = {
      openDrawer: false
    };
  }

  handleSumbit = values => {
    if (this.props.updateRequest) {
      const { _id, createdAt, updatedAt, __v, ...rest } = values;
      this.props.createRequest({ ...rest, member_id: _id });
      return;
    }

    if (this.props.newItem) {
      this.props.createMember({ ...values, approved: false });
    } else {
      this.props.updateMember(values);
    }
  };

  render() {
    return (
      <Drawer
        visible={this.props.visible}
        onClose={this.props.onClose}
        placement="top"
        height="100vh"
      >
        <MemberForm onSubmit={this.handleSumbit} {...this.props} />
      </Drawer>
    );
  }
}

const mapDispatchToProps = {
  createMember,
  updateMember,
  createRequest
};

export default connect(
  null,
  mapDispatchToProps
)(Member);
