import { createSelector } from "reselect";

export const isLoadingReports = createSelector(
  state => state.reports.isLoading,
  isLoading => isLoading
);

export const reportsSelector = createSelector(
  state => state.reports.reports,
  reports => reports
);
