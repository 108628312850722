import React, { Component, Fragment } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  reduxForm,
  Field,
  FieldArray,
  change,
  formValueSelector
} from "redux-form";
import { Input, Select, DatePicker } from "../../utils/formComponents";
import { membersSchemaSelector } from "../../redux/selectors";
import { required } from "../../utils/validators";

function getType(key, schema) {
  let item = schema.find(t => t.name === key);
  return item && item.type;
}

const ItemsFilter = ({ fields, filters, schemaOptions, schema }) => {
  const comparators = {
    String: [
      { label: "Contains", value: "Contains" },
      { label: "Starts With", value: "Starts With" }
    ],
    Select: [
      { label: "Contains", value: "Contains" },
      { label: "Starts With", value: "Starts With" }
    ],
    Number: [{ label: "Equals", value: "Equals" }],
    Date: [
      { label: "Is Before", value: "Is Before" },
      { label: "Is After", value: "Is After" },
      { label: "Is Between", value: "Is Between" }
    ]
  };

  return (
    <Fragment>
      {fields.map((member, index) => (
        <div key={index}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}
          >
            {index > 0 && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: 120, marginRight: 8 }}>
                  Logic (AND/OR):{" "}
                </div>

                <Field
                  name={`${member}.logic`}
                  component={Select}
                  placeholder="AND/OR"
                  style={{ width: 80 }}
                  options={[
                    { label: "AND", value: "AND" },
                    { label: "OR", value: "OR" }
                  ]}
                  defaultValue="AND"
                  validate={[required]}
                />
              </div>
            )}
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}
          >
            <div style={{ width: 120, marginRight: 8 }}>
              Condition {index + 1}:{" "}
            </div>

            <div style={{ marginRight: 8 }}>
              <Field
                name={`${member}.key`}
                component={Select}
                placeholder="Select parameter"
                style={{ width: 160 }}
                options={schemaOptions}
                validate={[required]}
              />
            </div>

            <div style={{ marginRight: 8 }}>
              <Field
                name={`${member}.comparator`}
                component={Select}
                placeholder="Select comparator"
                style={{ width: 160 }}
                options={
                  (filters &&
                    (filters[index] &&
                      filters[index].key &&
                      comparators[getType(filters[index].key, schema)])) ||
                  []
                }
                validate={[required]}
              />
            </div>

            {filters &&
              filters[index] &&
              (getType(filters[index].key, schema) === "String" ||
                getType(filters[index].key, schema) === "Select") && (
                <div style={{ marginRight: 8 }}>
                  <Field
                    name={`${member}.value`}
                    component={Input}
                    style={{ width: 160 }}
                  />
                </div>
              )}

            {filters &&
              filters[index] &&
              getType(filters[index].key, schema) === "Date" && (
                <div style={{ marginRight: 8 }}>
                  <Field
                    name={`${member}.value`}
                    component={DatePicker}
                    style={{ width: 160 }}
                  />
                </div>
              )}

            {filters &&
              filters[index] &&
              filters[index].comparator === "Is Between" && (
                <div style={{ marginRight: 8 }}>
                  <Field
                    name={`${member}.value2`}
                    component={DatePicker}
                    style={{ width: 160 }}
                  />
                </div>
              )}

            <div style={{ marginRight: 8 }}>
              <Button
                icon="minus-circle"
                type="link"
                onClick={() => fields.remove(index)}
              />
            </div>
          </div>
        </div>
      ))}
      <Button icon="plus" onClick={() => fields.push({})}>
        Add Filter
      </Button>
    </Fragment>
  );
};

class FilterForm extends Component {
  render() {
    const { handleSubmit, schema } = this.props;

    const schemaOptions =
      schema &&
      schema.map(t => ({
        label: t.name,
        value: t.name
      }));

    return (
      <form onSubmit={handleSubmit}>
        <FieldArray
          name="filters"
          component={ItemsFilter}
          filters={this.props.filters}
          schemaOptions={schemaOptions}
          schema={schema}
        />

        <div style={{ textAlign: "right", marginTop: 8 }}>
          <Button type="primary" onClick={handleSubmit}>
            Apply Filter & Run Report
          </Button>
        </div>
      </form>
    );
  }
}

FilterForm = reduxForm({
  form: "filter-form"
})(FilterForm);

let initialValues = {
  filters: [{}]
};

const selector = formValueSelector("filter-form");

const mapStateToProps = createSelector(
  (state, props) => ({ ...initialValues, ...props.initialValues }),
  state => selector(state, "filters"),
  membersSchemaSelector,
  (initialValues, filters, schema) => ({
    initialValues,
    filters,
    schema
  })
);

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change("filter-form", field, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
