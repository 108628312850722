import React, { Component } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Field, reduxForm } from "redux-form";
import { Input } from "../../utils/formComponents";
import { required } from "../../utils/validators";
import { membersSelector } from "../../redux/selectors";

class GroupForm extends Component {
  handleChange = targetKeys => {
    this.setState({ targetKeys });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <h1>{(this.props.newItem && "Create Group") || "Update Group"}</h1>

        <div style={{ marginBottom: 8 }}>
          <span className="bold-text">Group Name</span>
          <Field name="name" component={Input} validate={[required]} />
        </div>

        <div
          style={{
            width: "100%",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            marginTop: 16,
            marginBottom: -16
          }}
        >
          <Button
            style={{ marginRight: 8, width: 100 }}
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
          <Button style={{ width: 100 }} onClick={handleSubmit} type="primary">
            {(this.props.newItem && "Save") || "Update"}
          </Button>
        </div>
      </form>
    );
  }
}

GroupForm = reduxForm({
  form: "group-form"
})(GroupForm);

let initialValues = {};

const mapStateToProps = createSelector(
  membersSelector,
  (state, props) => ({ ...initialValues, ...props.initialValues }),
  (members, initialValues) => ({
    members,
    initialValues
  })
);

export default connect(mapStateToProps)(GroupForm);
