import * as types from "./types";

export function fetchRoles() {
  return {
    type: types.FETCH_ROLES
  };
}

export function createRole(payload) {
  return {
    type: types.CREATE_ROLE,
    payload
  };
}

export function updateRole(payload) {
  return {
    type: types.UPDATE_ROLE,
    payload
  };
}

export function deleteRole(payload) {
  return {
    type: types.DELETE_ROLE,
    payload
  };
}
