import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* fetchMembers() {
  try {
    const response = yield call(sendRequest, "members", "GET");
    yield put({ type: types.FETCH_MEMBERS_SUCCESS, payload: response.data });
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* createMember({ payload }) {
  try {
    const response = yield call(sendRequest, "members/create", "POST", payload);
    yield put({ type: types.FETCH_MEMBERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* updateMember({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `members/update/${payload._id}`,
      "PUT",
      payload
    );
    yield put({ type: types.FETCH_MEMBERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* deleteMember({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `members/delete/${payload._id}`,
      "DELETE",
      payload
    );
    yield put({ type: types.FETCH_MEMBERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* importMembers({ payload }) {
  try {
    const response = yield call(sendRequest, "members/import", "POST", payload);
    yield put({ type: types.FETCH_MEMBERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* deleteManyMembers({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      "members/delete-many",
      "POST",
      payload
    );
    yield put({ type: types.FETCH_MEMBERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* findMemberByNIC({ payload }) {
  try {
    const response = yield call(sendRequest, "members/nic", "POST", payload);
    yield put({
      type: types.FIND_MEMBER_BY_NIC_SUCCESS,
      payload: response.data.member
    });
  } catch (error) {
    yield put({ type: types.FIND_MEMBER_BY_NIC_FAILED });
    yield notifyError(error.response.data);
  }
}

function* approveMembers({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      "members/approve",
      "POST",
      payload
    );
    yield put({ type: types.APPROVE_MEMBERS_SUCCESS });
    yield put({ type: types.FETCH_MEMBERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield put({ type: types.APPROVE_MEMBERS_FAILED });
    yield notifyError(error.response.data);
  }
}

function* rejectMembers({ payload }) {
  try {
    const response = yield call(sendRequest, "members/reject", "POST", payload);
    yield put({ type: types.FETCH_MEMBERS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* membersSagaWatcher() {
  yield takeLatest(types.FETCH_MEMBERS, fetchMembers);
  yield takeEvery(types.CREATE_MEMBER, createMember);
  yield takeEvery(types.UPDATE_MEMBER, updateMember);
  yield takeEvery(types.DELETE_MEMBER, deleteMember);
  yield takeEvery(types.IMPORT_MEMBERS, importMembers);
  yield takeEvery(types.DELETE_MANY_MEMBERS, deleteManyMembers);
  yield takeLatest(types.FIND_MEMBER_BY_NIC, findMemberByNIC);
  yield takeEvery(types.APPROVE_MEMBERS, approveMembers);
  yield takeLatest(types.REJECT_MEMBERS, rejectMembers);
}
