import * as types from "./types";

export function fetchUsers() {
  return {
    type: types.FETCH_USERS
  };
}

export function createUser(payload) {
  return {
    type: types.CREATE_USER,
    payload
  };
}

export function updateUser(payload) {
  return {
    type: types.UPDATE_USER,
    payload
  };
}

export function deleteUser(payload) {
  return {
    type: types.DELETE_USER,
    payload
  };
}
