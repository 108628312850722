import React, { Component, Fragment } from "react";
import { Button, Spin, Divider, Tag } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  unapprovedMembersSelector,
  isLoadingMembers,
  isApprovingMembersSelector,
  membersSchemaSelector
} from "../redux/selectors";
import { showConfirmRejectMember } from "../utils/confirmDialogs";
import { fetchMembers, approveMembers, rejectMembers } from "../redux/actions";
import { hasMemberApprovePermission } from "../utils/permissions";
import { Table } from "../components/table";
import moment from "moment";
import { loadingIcon } from "../utils/loadingIcon";

class ApproveMembers extends Component {
  constructor() {
    super();
    this.state = {
      columns: [],
      selectedItems: []
    };

    this.selectedMember = undefined;
  }

  componentDidMount() {
    this.props.fetchMembers();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.schema &&
      (prevProps.schema !== this.props.schema ||
        this.state.columns.length === 0)
    ) {
      let columns = this.props.schema.filter(t => t.show === "Yes");
      columns = columns.map(t => ({
        title: t.name,
        key: t.name,
        align: t.align || "center",
        render:
          (t.type === "Date" && (t => moment(t).format("DD MMM YYYY"))) ||
          (t => t)
      }));
      this.setState({ columns });
    }
  }

  getDataSource = () => {
    const { members } = this.props;
    return members.map(t => ({
      ...t
    }));
  };

  onApprove = () => {
    const { selectedItems } = this.state;
    this.props.approveMembers(selectedItems);
    this.setState({ selectedItems: [] });
  };

  onReject = () => {
    const { selectedItems } = this.state;
    this.props.rejectMembers(selectedItems);
    this.setState({ selectedItems: [] });
  };

  onSelectChange = (checked, item) => {
    const { selectedItems } = this.state;
    if (checked) {
      let items = Object.assign([], selectedItems);
      if (item instanceof Array) {
        items = item;
      } else {
        items.push(item);
      }
      this.setState({ selectedItems: items });
    } else {
      if (item instanceof Array) {
        this.setState({ selectedItems: [] });
      } else {
        let items = selectedItems.filter(t => t._id !== item._id);
        this.setState({ selectedItems: items });
      }
    }
  };

  render() {
    const { isLoading, isApprovingMembers } = this.props;
    const { columns, selectedItems } = this.state;
    const dataSource = this.getDataSource();

    return (
      <Spin spinning={isLoading || isApprovingMembers} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 16 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <Button
              icon="arrow-left"
              onClick={() => this.props.history.goBack()}
            />
            <Divider type="vertical" />
            <span style={{ fontWeight: 400, fontSize: 28 }}>
              Unapproved Members
            </span>
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            {(hasMemberApprovePermission() && (
              <Fragment>
                <Button
                  type="primary"
                  style={{ marginLeft: 8, width: 120 }}
                  disabled={selectedItems.length === 0}
                  onClick={this.onApprove}
                >
                  Approve
                </Button>
                <Button
                  type="danger"
                  style={{ marginLeft: 8, width: 120 }}
                  disabled={selectedItems.length === 0}
                  onClick={() => showConfirmRejectMember(this.onReject)}
                >
                  Reject
                </Button>
              </Fragment>
            )) || (
              <Fragment>
                <Tag color="#faad14">PENDING</Tag>
              </Fragment>
            )}
          </div>
        </div>

        {columns && (
          <Table
            columns={columns}
            dataSource={dataSource}
            onClick={this.onViewMember}
            pagination
            selectable
            onSelectChange={this.onSelectChange}
            selectedItems={selectedItems}
            showSelectedCount
          />
        )}
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMembers,
  unapprovedMembersSelector,
  isApprovingMembersSelector,
  membersSchemaSelector,
  (isLoading, members, isApprovingMembers, schema) => ({
    isLoading,
    members,
    isApprovingMembers,
    schema
  })
);

const mapDispatchToProps = dispatch => {
  return {
    fetchMembers: () => dispatch(fetchMembers()),
    approveMembers: members => dispatch(approveMembers(members)),
    rejectMembers: members => dispatch(rejectMembers(members))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveMembers);
