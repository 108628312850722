import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isLoadingMembers, membersSelector } from "../redux/selectors";
import moment from "moment";
import { loadingIcon } from "../utils/loadingIcon";

class JoinedThisYearCard extends Component {
  getData = () => {
    const { members } = this.props;
    let thisMonth = moment().format("YYYY");
    let total = 0;
    members &&
      members.forEach(member => {
        let month = moment(member["Joined Date"]).format("YYYY");
        if (month === thisMonth) total += 1;
      });

    return total;
  };

  render() {
    const { isLoading } = this.props;

    return (
      <div
        style={{
          background: "#ef5675",
          padding: 16,
          minHeight: 80,
          borderRadius: 8,
          boxShadow: "1px 2px 4px rgba(100, 100, 100, .8)"
        }}
      >
        <Spin spinning={isLoading} indicator={loadingIcon}>
          <div
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: 16,
              color: "#fff"
            }}
          >
            Joined This Year
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 48,
              color: "#fff"
            }}
          >
            {this.getData()}
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMembers,
  membersSelector,
  (isLoading, members) => ({
    isLoading,
    members
  })
);

export default connect(mapStateToProps)(JoinedThisYearCard);
