import React, { Component } from "react";
import { Spin, Button, Divider } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { fetchMembers } from "../redux/actions";
import {
  isLoadingMembers,
  membersSchemaSelector,
  approvedMembersSelector
} from "../redux/selectors";
import { Table } from "../components/table";
import moment from "moment";

import { loadingIcon } from "../utils/loadingIcon";

class DeceasedList extends Component {
  constructor() {
    super();
    this.state = {
      columns: [],
      selectedItems: []
    };

    this.selectedMember = undefined;
  }

  componentDidMount() {
    this.props.fetchMembers();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.schema &&
      (prevProps.schema !== this.props.schema ||
        this.state.columns.length === 0)
    ) {
      let columns = this.props.schema.filter(t => t.show === "Yes");
      columns = columns.map(t => ({
        title: t.name,
        key: t.name,
        align: t.align || "center",
        render:
          (t.type === "Date" && (t => moment(t).format("DD MMM YYYY"))) ||
          (t => t)
      }));
      this.setState({ columns });
    }
  }

  getDataSource = () => {
    const { members } = this.props;
    return members.filter(t => t["Deceased"] === "Yes");
  };

  onSelectChange = (checked, item) => {
    const { selectedItems } = this.state;
    if (checked) {
      let items = Object.assign([], selectedItems);
      if (item instanceof Array) {
        items = item;
      } else {
        items.push(item);
      }
      this.setState({ selectedItems: items });
    } else {
      if (item instanceof Array) {
        this.setState({ selectedItems: [] });
      } else {
        let items = selectedItems.filter(t => t._id !== item._id);
        this.setState({ selectedItems: items });
      }
    }
  };

  render() {
    const { isLoading } = this.props;
    const { columns } = this.state;
    const dataSource = this.getDataSource();

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 16 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <Button
              icon="arrow-left"
              onClick={() => this.props.history.goBack()}
            />
            <Divider type="vertical" />
            <span style={{ fontWeight: 400, fontSize: 20 }}>Report</span>
            <Divider type="vertical" />
            <span style={{ fontWeight: 600, fontSize: 20 }}>
              Deceased Members
            </span>
          </div>
        </div>

        {columns && (
          <Table
            columns={columns}
            dataSource={dataSource}
            onClick={this.onViewMember}
            pagination
            // selectable
            // onSelectChange={this.onSelectChange}
            // selectedItems={selectedItems}
            // showSelectedCount
          />
        )}
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMembers,
  approvedMembersSelector,
  membersSchemaSelector,
  (isLoading, members, schema) => ({
    isLoading,
    members,
    schema
  })
);

const mapDispatchToProps = dispatch => {
  return {
    fetchMembers: () => dispatch(fetchMembers())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeceasedList);
