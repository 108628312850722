import { createSelector } from "reselect";

export const isLoadingAuth = createSelector(
  state => state.auth.isLoading,
  isLoading => isLoading
);

export const authSelector = createSelector(
  state => state.auth.auth,
  auth => auth
);

export const isVerifyingEmailSelector = createSelector(
  state => state.auth.isVerifying,
  isVerifying => isVerifying
);

export const emailVerifiedSelector = createSelector(
  state => state.auth.verified,
  verified => verified
);
