import React, { Component, Fragment } from "react";
import { Row, Col, Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { membersSelector, isLoadingMembers } from "../../redux/selectors";
import { reduxForm, Field, change } from "redux-form";
import { Input, DatePicker, Select } from "../../utils/formComponents";
import { membersSchemaSelector } from "../../redux/selectors";
import moment from "moment";
import { required, number } from "../../utils/validators";

const ItemInput = props => (
  <Row
    gutter={16}
    style={{ marginBottom: 8, background: props.hasChanged && "yellow" }}
  >
    <Col span={8}>
      <div className="bold-text" style={{ marginLeft: 16 }}>
        {props.label}
      </div>
    </Col>
    <Col span={8}>
      <div>{props.old}</div>
    </Col>
    <Col span={8}>
      <div>
        <Field name={props.name} component={Input} {...props} />
      </div>
    </Col>
  </Row>
);

const ItemDatePicker = props => (
  <Row
    gutter={16}
    style={{ marginBottom: 8, background: props.hasChanged && "yellow" }}
  >
    <Col span={8}>
      <div className="bold-text" style={{ marginLeft: 16 }}>
        {props.label}
      </div>
    </Col>
    <Col span={8}>
      <div>{moment(props.old).format("DD MMM YYYY")}</div>
    </Col>
    <Col span={8}>
      <div>
        <Field
          name={props.name}
          component={DatePicker}
          style={{ width: "100%" }}
          {...props}
        />
      </div>
    </Col>
  </Row>
);

const ItemSelect = props => (
  <Row
    gutter={16}
    style={{ marginBottom: 8, background: props.hasChanged && "yellow" }}
  >
    <Col span={8}>
      <div className="bold-text" style={{ marginLeft: 16 }}>
        {props.label}
      </div>
    </Col>
    <Col span={8}>
      <div>{props.old}</div>
    </Col>
    <Col span={8}>
      <div>
        <Field
          name={props.name}
          component={Select}
          style={{ width: "100%" }}
          {...props}
        />
      </div>
    </Col>
  </Row>
);

class RequestForm extends Component {
  getValidators = item => {
    let validators = [];
    if (item.required === "Yes") validators.push(required);
    if (item.type === "Number") validators.push(number);
    if (item.type === "Date") validators.push(required);
    return validators;
  };

  getOptions = item => {
    let options = [];
    let ops = item.options.split(",");
    ops.forEach(t => {
      options.push({ label: t, value: t });
    });
    return options;
  };

  shouldDisable = item => {
    if (item.editable === "No") return true;
    return false;
  };

  hasChanged = key => {
    const { initialValues, members } = this.props;
    const member = members.find(t => t._id === initialValues.member_id);
    return initialValues[key] !== member[key];
  };

  render() {
    const { handleSubmit, initialValues, members, schema } = this.props;
    const member = members.find(t => t._id === initialValues.member_id);

    return (
      <form onSubmit={handleSubmit}>
        <Row
          gutter={16}
          className="bold-text"
          style={{ marginBottom: 0, fontSize: 16 }}
        >
          <Col span={8}></Col>
          <Col span={8}>
            <div>Old Value</div>
          </Col>
          <Col span={8}>
            <div>New Value</div>
          </Col>
        </Row>

        {schema.map((item, index) => (
          <Fragment key={index}>
            {(item.type === "String" || item.type === "Number") && (
              <ItemInput
                label={item.name}
                old={member && member[item.name]}
                name={item.name}
                validate={this.getValidators(item)}
                disabled={this.shouldDisable(item)}
                hasChanged={this.hasChanged(item.name)}
              />
            )}
            {item.type === "Date" && (
              <ItemDatePicker
                label={item.name}
                old={member && member[item.name]}
                name={item.name}
                validate={this.getValidators(item)}
                disabled={this.shouldDisable(item)}
                hasChanged={this.hasChanged(item.name)}
              />
            )}
            {item.type === "Select" && (
              <ItemSelect
                label={item.name}
                old={member && member[item.name]}
                name={item.name}
                options={this.getOptions(item)}
                validate={this.getValidators(item)}
                disabled={this.shouldDisable(item)}
                hasChanged={this.hasChanged(item.name)}
              />
            )}
          </Fragment>
        ))}

        <div style={{ textAlign: "right", marginTop: 24 }}>
          <Button
            style={{ marginRight: 8, width: 100 }}
            type="danger"
            onClick={() => this.props.onReject(initialValues)}
            disabled={
              initialValues.status === "approved" ||
              initialValues.status === "rejected"
            }
          >
            Reject
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={initialValues.status === "approved"}
          >
            Approve & Update Member
          </Button>
        </div>
      </form>
    );
  }
}

RequestForm = reduxForm({
  form: "request-form",
  enableReinitialize: true
})(RequestForm);

let initialValues = {};

const mapStateToProps = createSelector(
  isLoadingMembers,
  membersSelector,
  membersSchemaSelector,
  (state, props) => ({ ...initialValues, ...props.initialValues }),
  (isLoading, members, schema, initialValues) => ({
    isLoading,
    members,
    schema,
    initialValues
  })
);

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change("member-form", field, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestForm);
