import * as types from "../actions/types";

let initialState = {
  isLoading: false,
  users: []
};

export function usersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_USERS:
      return { ...state, isLoading: true };
    case types.FETCH_USERS_SUCCESS:
      return { ...state, users: payload, isLoading: false };
    case types.CREATE_USER_SUCCESS:
      return { ...state, users: [...state.users, payload.newItem] };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(item => {
          if (item._id !== payload._id) {
            return item;
          }
          return {
            ...item,
            ...payload.item
          };
        })
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(item => item._id !== payload._id)
      };

    default:
      return state;
  }
}
