import React, { Component } from "react";
import { Icon } from "antd";

class SimpleList extends Component {
  render() {
    const { dataSource } = this.props;

    return (
      <div>
        {dataSource &&
          dataSource.map((item, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() =>
                  this.props.onClick ? this.props.onClick(item.value) : null
                }
                className="table-highlight"
                style={{
                  width: `calc(100% - 10px)`,
                  textAlign: "left",
                  cursor: "pointer",
                  padding: "8px 8px",
                  borderBottom: "1px solid #eee"
                }}
              >
                {item.label}
              </div>
              {this.props.showDelete && (
                <div
                  onClick={() =>
                    this.props.onDelete ? this.props.onDelete(item.value) : null
                  }
                  className="table-highlight"
                  style={{
                    cursor: "pointer",
                    padding: "8px 8px",
                    borderBottom: "1px solid #eee"
                  }}
                >
                  <Icon type="delete" />
                </div>
              )}
            </div>
          ))}
      </div>
    );
  }
}

export { SimpleList };
