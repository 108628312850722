import React, { Component } from "react";
import { Button, Row, Col, Divider } from "antd";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { Input, Checkbox } from "../../utils/formComponents";
import { required } from "../../utils/validators";

const Module = props => (
  <Row gutter={16} style={{ marginBottom: 8 }}>
    <Col span={8}>{props.label}</Col>
    <Col span={4} style={{ textAlign: "center" }}>
      <Field
        name={`${props.name}.read`}
        component={Checkbox}
        disabled={props.disabled}
      />
    </Col>
    <Col span={4} style={{ textAlign: "center" }}>
      <Field
        name={`${props.name}.create`}
        component={Checkbox}
        disabled={props.disabled}
      />
    </Col>
    <Col span={4} style={{ textAlign: "center" }}>
      <Field
        name={`${props.name}.update`}
        component={Checkbox}
        disabled={props.disabled}
      />
    </Col>
    <Col span={4} style={{ textAlign: "center" }}>
      <Field
        name={`${props.name}.delete`}
        component={Checkbox}
        disabled={props.disabled}
      />
    </Col>
  </Row>
);

const Permission = props => (
  <Row gutter={16} style={{ marginBottom: 8 }}>
    <Col span={8}>{props.label}</Col>
    <Col span={4} style={{ textAlign: "center" }}>
      <Field
        name={`${props.name}`}
        component={Checkbox}
        disabled={props.disabled}
      />
    </Col>
  </Row>
);

const MODULES = [
  { label: "Members", value: "members" },
  { label: "Reports", value: "reports" }
];

const PERMISSIONS = [
  { label: "Approve/Reject Members", value: "approveRejectMembers" },
  { label: "Approve/Reject Requests", value: "approveRejectRequests" }
];

class RoleForm extends Component {
  isAdmin = () => {
    const role = this.props.initialValues;
    if (role && role.identifier === "admin") {
      MODULES.forEach(t => {
        this.props.changeFieldValue(`permissions.${t.value}.read`, true);
        this.props.changeFieldValue(`permissions.${t.value}.create`, true);
        this.props.changeFieldValue(`permissions.${t.value}.update`, true);
        this.props.changeFieldValue(`permissions.${t.value}.delete`, true);
      });

      PERMISSIONS.forEach(t => {
        this.props.changeFieldValue(`permissions.${t.value}`, true);
      });

      return true;
    }
    return false;
  };

  render() {
    const { handleSubmit } = this.props;
    const role = this.props.initialValues;

    return (
      <form onSubmit={handleSubmit}>
        <h1>{(this.props.newItem && "Create Role") || "Update Role"}</h1>

        <div style={{ marginBottom: 8 }}>
          <span className="bold-text">Role Name</span>
          <Field
            name="name"
            component={Input}
            validate={[required]}
            disabled={this.isAdmin()}
          />
        </div>

        <div style={{ marginBottom: 8, marginTop: 16 }}>
          <span className="bold-text">Permissions</span>

          <Row gutter={16} style={{ marginBottom: 8 }}>
            <Col span={8} style={{ textAlign: "center" }}></Col>
            <Col span={4} style={{ textAlign: "center" }}>
              Read
            </Col>
            <Col span={4} style={{ textAlign: "center" }}>
              Create
            </Col>
            <Col span={4} style={{ textAlign: "center" }}>
              Update
            </Col>
            <Col span={4} style={{ textAlign: "center" }}>
              Delete
            </Col>
          </Row>

          {MODULES.map((item, index) => (
            <Module
              key={index}
              label={item.label}
              name={`permissions.${item.value}`}
              disabled={this.isAdmin()}
            />
          ))}

          <Divider />

          {PERMISSIONS.map((item, index) => (
            <Permission
              key={index}
              label={item.label}
              name={`permissions.${item.value}`}
              disabled={this.isAdmin()}
            />
          ))}
        </div>

        <div
          style={{
            width: "100%",
            padding: "10px 0px",
            background: "#fff",
            textAlign: "right",
            marginTop: 32,
            marginBottom: -16
          }}
        >
          <Button
            style={{ marginRight: 8, width: 100 }}
            onClick={this.props.onClose}
          >
            Cancel
          </Button>

          {!this.props.newItem && !role.permenant && (
            <Button
              style={{ marginRight: 8, width: 100 }}
              onClick={() => this.props.onDelete(role)}
            >
              Delete
            </Button>
          )}

          <Button
            style={{ width: 100 }}
            onClick={handleSubmit}
            type="primary"
            disabled={this.isAdmin()}
          >
            {(this.props.newItem && "Save") || "Update"}
          </Button>
        </div>
      </form>
    );
  }
}

RoleForm = reduxForm({
  form: "role-form"
})(RoleForm);

// const selector = formValueSelector("Expense-form");

// let initialValues = {
//   permissions: {}
// };

// const mapStateToProps = createSelector(
//   (state, props) => ({...initialValues, ...props.initialValues}),
//   initialValues => ({
//     initialValues
//   })
// );

const mapDispatchToProps = dispatch => {
  return {
    changeFieldValue: (field, value) => {
      dispatch(change("role-form", field, value));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RoleForm);
