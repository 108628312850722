import React, { Component } from "react";
import { Spin, Icon, Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reset } from "redux-form";
import { Table } from "./table";
import { rolesSelector, isLoadingRoles } from "../redux/selectors";
import moment from "moment";
import Role from "../forms/role";
import { showConfirmDeleteRole } from "../utils/confirmDialogs";
import { deleteRole } from "../redux/actions";

import { loadingIcon } from "../utils/loadingIcon";

const columns = [
  {
    title: "Role Name",
    key: "name"
  },
  {
    title: "Created At",
    key: "createdAt",
    align: "center",
    render: t => moment(t).format("DD MMM YYYY")
  }
];

class ManageRoles extends Component {
  constructor() {
    super();
    this.state = {
      isRoleVisible: false
    };
    this.newItem = true;
  }

  getDataSource = () => {
    const { roles } = this.props;
    return roles.map(t => ({
      ...t
    }));
  };

  onRole = () => {
    this.newItem = true;
    this.setState({ initialRoleValues: {}, isRoleVisible: true });
  };

  onCloseRole = () => {
    this.setState({ isRoleVisible: false });
    this.props.resetForm();
  };

  onRoleSubmitSuccess = () => {
    this.setState({ isRoleVisible: false });
    this.props.resetForm();
  };

  onEditRole = item => {
    this.newItem = false;
    this.setState({ initialRoleValues: item, isRoleVisible: true });
  };

  onDeleteRole = role => {
    showConfirmDeleteRole(() => this.onConfirmDeleteRole(role));
  };

  onConfirmDeleteRole = role => {
    this.props.deleteRole(role);
    this.setState({ isRoleVisible: false });
  };

  render() {
    const { isLoading } = this.props;
    const dataSource = this.getDataSource();

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ flex: 3, textAlign: "right" }}>
            <Button onClick={this.onRole}>
              <Icon type="plus" />
              New
            </Button>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          onClick={this.onEditRole}
          pagination
          hideSearch
        />

        <Role
          visible={this.state.isRoleVisible}
          onClose={this.onCloseRole}
          onSubmitSuccess={this.onRoleSubmitSuccess}
          initialValues={this.state.initialRoleValues}
          enableReinitialize
          newItem={this.newItem}
          onDelete={this.onDeleteRole}
        />
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingRoles,
  rolesSelector,
  (isLoading, roles) => ({
    isLoading,
    roles
  })
);

const mapDispatchToProps = dispatch => {
  return {
    resetForm: () => dispatch(reset("role-form")),
    deleteRole: role => dispatch(deleteRole(role))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageRoles);
