import * as types from "./types";

export function fetchMembers() {
  return {
    type: types.FETCH_MEMBERS
  };
}

export function createMember(payload) {
  return {
    type: types.CREATE_MEMBER,
    payload
  };
}

export function updateMember(payload) {
  return {
    type: types.UPDATE_MEMBER,
    payload
  };
}

export function deleteMember(payload) {
  return {
    type: types.DELETE_MEMBER,
    payload
  };
}

export function importMembers(payload) {
  return {
    type: types.IMPORT_MEMBERS,
    payload
  };
}

export function deleteManyMembers(payload) {
  return {
    type: types.DELETE_MANY_MEMBERS,
    payload
  };
}

export function findMemberByNIC(payload) {
  return {
    type: types.FIND_MEMBER_BY_NIC,
    payload
  };
}

export function approveMembers(payload) {
  return {
    type: types.APPROVE_MEMBERS,
    payload
  };
}

export function rejectMembers(payload) {
  return {
    type: types.REJECT_MEMBERS,
    payload
  };
}
