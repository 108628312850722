import React, { Component } from "react";
import { Row, Col, Icon, Spin, Input } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reset } from "redux-form";
import { groupsSelector, isLoadingGroups } from "../redux/selectors";
import { SimpleList } from "../components/simpleList";
import { deleteGroup } from "../redux/actions";
import { showConfirmDeleteGroup } from "../utils/confirmDialogs";

import { loadingIcon } from "../utils/loadingIcon";

class GroupsPage extends Component {
  constructor() {
    super();
    this.state = {
      searchQuery: ""
    };
  }

  filterGroups(query) {
    const { groups } = this.props;
    if (query === "") {
      return groups;
    }
    const regex = new RegExp(
      `${query.toString().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}`,
      "i"
    );
    return groups.filter(group => group.name && group.name.search(regex) >= 0);
  }

  /** ********************************************************************** **/

  handleSavedItemClick = item => {
    const { groups, history } = this.props;
    let group = groups.find(t => t._id === item);
    history.push("/group-general", { group, newItem: false });
  };

  onDeleteGroup = item => {
    this.props.deleteGroup({ _id: item });
  };

  render() {
    const { isLoading } = this.props;
    const groups = this.filterGroups(this.state.searchQuery);

    const groupsList = groups.map(t => ({
      label: t.name,
      value: t._id
    }));

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 16 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <span style={{ fontWeight: 400, fontSize: 28 }}>Groups</span>
            <Input.Search
              placeholder="Search"
              onChange={e => this.setState({ searchQuery: e.target.value })}
              style={{ width: 200, marginLeft: 16 }}
            />
          </div>

          {/* <div style={{ flex: 1, textAlign: "right" }}>
            <Button onClick={this.onGroup}>
              <Icon type="plus" />
              New Group
            </Button>
          </div> */}
        </div>

        <Row gutter={16} style={{ marginTop: 8 }}>
          <Col span={12}>
            <div style={{ fontSize: 18, fontWeight: 400, marginBottom: 4 }}>
              <Icon type="database" /> SAVED GROUPS
            </div>
            <div style={{ width: "80%" }}>
              <SimpleList
                dataSource={groupsList}
                onClick={this.handleSavedItemClick}
                showDelete
                onDelete={item =>
                  showConfirmDeleteGroup(() => this.onDeleteGroup(item))
                }
              />
            </div>
          </Col>
        </Row>
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingGroups,
  groupsSelector,
  (isLoading, groups) => ({
    isLoading,
    groups
  })
);

const mapDispatchToProps = dispatch => {
  return {
    deleteGroup: group => dispatch(deleteGroup(group)),
    resetForm: () => dispatch(reset("group-form"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsPage);
