import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isLoadingRequests, pendingRequestsSelector } from "../redux/selectors";
import { loadingIcon } from "../utils/loadingIcon";

class UpdateRequestCard extends Component {
  render() {
    const { isLoading, requests } = this.props;

    return (
      <div
        style={{
          background: "#ffa600",
          padding: 16,
          minHeight: 80,
          borderRadius: 8,
          boxShadow: "1px 2px 4px rgba(100, 100, 100, .8)"
        }}
      >
        <Spin spinning={isLoading} indicator={loadingIcon}>
          <div
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: 16,
              color: "#fff"
            }}
          >
            Update Requests
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 48,
              color: "#fff"
            }}
          >
            {requests.length}
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingRequests,
  pendingRequestsSelector,
  (isLoading, requests) => ({
    isLoading,
    requests
  })
);

export default connect(mapStateToProps)(UpdateRequestCard);
