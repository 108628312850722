import * as types from "./types";

export function authenticate(payload) {
  return {
    type: types.AUTHENTICATE,
    payload
  };
}

export function authenticateSuccess(payload) {
  return {
    type: types.AUTHENTICATE_SUCCESS,
    payload
  };
}

export function authenticateFailed(payload) {
  return {
    type: types.AUTHENTICATE_FAILED,
    payload
  };
}

export function verifyEmail(payload) {
  return {
    type: types.VERIFY_EMAIL,
    payload
  };
}

export function verifyEmailSuccess(payload) {
  return {
    type: types.VERIFY_EMAIL_SUCCESS,
    payload
  };
}

export function verifyEmailFailed(payload) {
  return {
    type: types.VERIFY_EMAIL_FAILED,
    payload
  };
}

export function forgotPassword(payload) {
  return {
    type: types.FORGOT_PASSWORD,
    payload
  };
}

export function forgotPasswordSuccess(payload) {
  return {
    type: types.FORGOT_PASSWORD_SUCCESS,
    payload
  };
}

export function forgotPasswordFailed(payload) {
  return {
    type: types.FORGOT_PASSWORD_FAILED,
    payload
  };
}
