import { createSelector } from "reselect";

export const isLoadingUsers = createSelector(
  state => state.users.isLoading,
  isLoading => isLoading
);

export const usersSelector = createSelector(
  state => state.users.users,
  users => users
);
