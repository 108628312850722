import React, { Component } from "react";
import { Spin, Icon, Tag, Button } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reset } from "redux-form";
import { Table } from "./table";
import { usersSelector, isLoadingUsers } from "../redux/selectors";
import moment from "moment";
import User from "../forms/user";
import { deleteUser } from "../redux/actions";
import { showConfirmDeleteUser } from "../utils/confirmDialogs";

import { loadingIcon } from "../utils/loadingIcon";

const columns = [
  {
    title: "Username",
    key: "username"
  },
  {
    title: "Email",
    key: "email"
  },
  {
    title: "Role",
    key: "role.name",
    align: "center"
  },
  {
    title: "Created At",
    key: "createdAt",
    align: "center",
    render: t => moment(t).format("DD MMM YYYY")
  },
  {
    title: "Verified",
    key: "isVerified",
    align: "center",
    render: t =>
      (t && <Tag color="#389e0d">YES</Tag>) || <Tag color="#f5222d">NO</Tag>
  }
];

class ManageUsers extends Component {
  constructor() {
    super();
    this.state = {
      isUserVisible: false
    };
    this.newItem = true;
  }

  getDataSource = () => {
    const { users } = this.props;
    return users.map(t => ({
      ...t
    }));
  };

  onUser = () => {
    this.newItem = true;
    this.setState({ initialUserValues: {}, isUserVisible: true });
  };

  onCloseUser = () => {
    this.setState({ isUserVisible: false });
    this.props.resetForm();
  };

  onUserSubmitSuccess = () => {
    this.setState({ isUserVisible: false });
    this.props.resetForm();
  };

  onEditUser = item => {
    this.newItem = false;
    this.setState({
      initialUserValues: {
        ...item,
        role: (item.role && item.role._id) || undefined
      },
      isUserVisible: true
    });
  };

  onDeleteUser = user => {
    showConfirmDeleteUser(() => this.onConfirmDeleteUser(user));
  };

  onConfirmDeleteUser = user => {
    this.props.deleteUser(user);
    this.setState({ isUserVisible: false });
  };

  render() {
    const { isLoading } = this.props;
    const dataSource = this.getDataSource();

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ flex: 3, textAlign: "right" }}>
            <Button onClick={this.onUser}>
              <Icon type="plus" />
              New
            </Button>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          onClick={this.onEditUser}
          pagination
          hideSearch
        />

        <User
          visible={this.state.isUserVisible}
          onClose={this.onCloseUser}
          onSubmitSuccess={this.onUserSubmitSuccess}
          initialValues={this.state.initialUserValues}
          enableReinitialize
          newItem={this.newItem}
          onDelete={this.onDeleteUser}
        />
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingUsers,
  usersSelector,
  (isLoading, users) => ({
    isLoading,
    users
  })
);

const mapDispatchToProps = dispatch => {
  return {
    resetForm: () => dispatch(reset("user-form")),
    deleteUser: user => dispatch(deleteUser(user))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsers);
