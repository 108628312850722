import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

// books reducers
import { membersReducer } from "./membersReducer";
import { authReducer } from "./authReducer";
import { requestsReducer } from "./requestsReducer";
import { reportsReducer } from "./reportsReducer";
import { settingsReducer } from "./settingsReducer";
import { usersReducer } from "./usersReducer";
import { rolesReducer } from "./rolesReducer";
import { activityLogReducer } from "./activityLogReducer";
import { groupsReducer } from "./groupsReducer";

const rootReducer = combineReducers({
  form: formReducer,
  members: membersReducer,
  auth: authReducer,
  requests: requestsReducer,
  reports: reportsReducer,
  settings: settingsReducer,
  users: usersReducer,
  roles: rolesReducer,
  activityLog: activityLogReducer,
  groups: groupsReducer
});

export { rootReducer };
