import * as types from "../actions/types";

let initialState = {
  isLoading: false,
  activityLog: []
};

export function activityLogReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_ACTIVITY_LOG:
      return { ...state, isLoading: true };
    case types.FETCH_ACTIVITY_LOG_SUCCESS:
      return { ...state, activityLog: payload, isLoading: false };

    default:
      return state;
  }
}
