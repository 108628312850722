import React, { Component } from "react";
import { Modal } from "antd";
import GroupForm from "./GroupForm";
import { connect } from "react-redux";
import { createGroup, updateGroup } from "../../redux/actions";

class Group extends Component {
  handleSubmit = values => {
    if (this.props.newItem) {
      this.props.createGroup(values);
    } else {
      // this.props.updateGroup(values);
    }
  };

  render() {
    return (
      <Modal
        width="64vw"
        footer={null}
        onCancel={this.props.onClose}
        {...this.props}
      >
        <GroupForm onSubmit={this.handleSubmit} {...this.props} />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  createGroup,
  updateGroup
};

export default connect(
  null,
  mapDispatchToProps
)(Group);
