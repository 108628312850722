import React, { Component } from "react";
import { Modal, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  StyleSheet
} from "@react-pdf/renderer";
import PrintForm from "./PrintForm";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  }
});

class Print extends Component {
  constructor() {
    super();
    this.state = {
      pageSize: "A4",
      fontSize: 11,
      width: "90mm",
      height: "36mm"
    };
  }

  handleSubmit = values => {
    const { pageSize, fontSize, width, height } = values;
    this.setState({
      pageSize,
      fontSize,
      width: `${width}mm`,
      height: `${height}mm`
    });
  };

  getDocument = members => {
    const { pageSize, fontSize, width, height } = this.state;

    return (
      <Document>
        <Page style={styles.body} size={pageSize} wrap>
          <View
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {members.map((member, index) => (
              <View
                key={index}
                style={{
                  border: "1 solid black",
                  padding: 4,
                  margin: 2,
                  width,
                  height
                }}
                wrap={false}
              >
                <Text style={{ fontSize }}>{member["LM No"]}</Text>
                <Text style={{ fontSize, marginTop: 2 }}>
                  {member["Title"]} {member["Print Name"]} {member["Surname"]}
                </Text>
                <Text style={{ fontSize, marginTop: 2 }}>
                  {member["Address 1"]}
                </Text>
                <Text style={{ fontSize, marginTop: 2 }}>
                  {member["Address 2"]}
                </Text>
                <Text style={{ fontSize, marginTop: 2 }}>{member["City"]}</Text>
                <Text style={{ fontSize, marginTop: 2 }}>
                  {member["State"]} {member["Postal Code"]}
                </Text>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  };

  render() {
    const { initialValues } = this.props;

    if (!initialValues) return <div />;

    return (
      <Modal
        width="80vw"
        footer={null}
        closable={false}
        onCancel={this.props.onClose}
        centered
        {...this.props}
      >
        <Row gutter={16}>
          <Col span={6}>
            <PrintForm onSubmit={this.handleSubmit} />
          </Col>
          <Col span={18}>
            <PDFViewer width="100%" height={640}>
              {this.getDocument(initialValues)}
            </PDFViewer>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default connect(
  null,
  null
)(Print);
