import React from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";

export const loadingIcon = (
  <Spinner color="#3949AB" size={32} speed={1} animating={true} />
);

export const loader = () => (
  <div
    style={{
      height: "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <Spinner color="#3949AB" size={32} speed={1} animating={true} />
  </div>
);
