import React, { Component } from "react";
import { Dropdown, Menu, Icon, Spin, Button, Badge } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { fetchMembers } from "../redux/actions";
import {
  isLoadingMembers,
  membersSchemaSelector,
  unapprovedMembersSelector,
  approvedMembersSelector
} from "../redux/selectors";
import Member from "../forms/member";
import { Table } from "../components/table";
import {
  showConfirmDeleteMember,
  showConfirmDeleteManyMembers
} from "../utils/confirmDialogs";
import { deleteManyMembers, deleteMember } from "../redux/actions";
import { reset } from "redux-form";
import ViewMember from "../components/ViewMember";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import {
  hasCreatePermission,
  hasUpdatePermission,
  hasDeletePermission
} from "../utils/permissions";
import Group from "../forms/group";

import { loadingIcon } from "../utils/loadingIcon";

class MembersPage extends Component {
  constructor() {
    super();
    this.state = {
      columns: [],
      isMemberVisible: false,
      isSendEmailVisible: false,
      isGroupVisible: false,
      selectedSearchFilter: "Surname",
      selectedItems: []
    };

    this.selectedMember = undefined;
  }

  componentDidMount() {
    this.props.fetchMembers();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.schema &&
      (prevProps.schema !== this.props.schema ||
        this.state.columns.length === 0)
    ) {
      let columns = this.props.schema.filter(t => t.show === "Yes");
      columns = columns.map(t => ({
        title: t.name,
        key: t.name,
        align: t.align || "center",
        render:
          (t.type === "Date" && (t => moment(t).format("DD MMM YYYY"))) ||
          (t => t)
      }));
      this.setState({ columns });
    }
  }

  getDataSource = () => {
    const { members } = this.props;
    return members.map(t => ({
      ...t
    }));
  };

  exportData = () => {
    const { members } = this.props;
    const { columns } = this.state;

    // map data before exporting
    let data = members.map(t => {
      let { _id, createdAt, updatedAt, __v, ...rest } = t;
      for (let column of columns) {
        if (!rest[column.key]) rest[column.key] = "";
      }
      return rest;
    });

    const options = {
      filename: "members",
      showLabels: true,
      useKeysAsHeaders: true
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  /** ********************************************************************** **/

  onMember = () => {
    this.newItem = true;
    this.setState({ initialMemberValues: null, isMemberVisible: true });
  };

  onCloseMember = () => {
    this.setState({ initialMemberValues: null, isMemberVisible: false });
    // this.props.resetForm();
  };

  onMemberSubmitSuccess = () => {
    this.setState({
      initialMemberValues: null,
      isMemberVisible: false,
      selectedItems: []
    });
    // this.props.resetForm();
  };

  /** ********************************************************************** **/

  onEdit = () => {
    this.newItem = false;
    let member = Object.assign({}, this.state.selectedItems[0]);
    this.setState({
      initialMemberValues: member,
      isMemberVisible: true
    });
  };

  onDelete = () => {
    showConfirmDeleteManyMembers(this.onConfirmDelete);
  };

  onConfirmDelete = () => {
    const { selectedItems } = this.state;
    this.props.deleteManyMembers(selectedItems);
    this.setState({ selectedItems: [] });
  };

  onEditMember = member => {
    this.newItem = false;
    this.setState({
      initialMemberValues: member,
      isViewMemberVisible: false,
      isMemberVisible: true
    });
  };

  onDeleteMember = member => {
    showConfirmDeleteMember(() => this.onConfirmDeleteMember(member));
  };

  onConfirmDeleteMember = member => {
    this.props.deleteMember(member);
    this.setState({ isViewMemberVisible: false, selectedItems: [] });
  };

  /** ********************************************************************** **/

  onViewMember = item => {
    this.selectedMember = item;
    this.setState({ isViewMemberVisible: true });
  };

  onCloseViewMember = () => {
    this.setState({ isViewMemberVisible: false });
  };

  /** ********************************************************************** **/

  onGroup = () => {
    const { selectedItems } = this.state;
    this.newItem = true;
    this.setState({
      initialGroupValues: { members: selectedItems },
      isGroupVisible: true
    });
  };

  onCloseGroup = () => {
    this.setState({ isGroupVisible: false });
  };

  onGroupSubmitSuccess = () => {
    this.setState({ isGroupVisible: false, selectedItems: [] });
    this.props.resetForm();
  };

  /** ********************************************************************** **/

  onApproveMembers = () => {
    const { history } = this.props;
    history.push("/approve-members");
  };

  handleSearchFilterChange = e => {
    this.setState({ selectedSearchFilter: e });
  };

  handleMenuClick = ({ key }) => {
    const { history } = this.props;
    switch (key) {
      case "importMembers":
        history.push("/import-members");
        break;
      case "exportData":
        this.exportData();
        break;
      default:
        return;
    }
  };

  onSelectChange = (checked, item) => {
    const { selectedItems } = this.state;
    if (checked) {
      let items = Object.assign([], selectedItems);
      if (item instanceof Array) {
        items = item;
      } else {
        items.push(item);
      }
      this.setState({ selectedItems: items });
    } else {
      if (item instanceof Array) {
        this.setState({ selectedItems: [] });
      } else {
        let items = selectedItems.filter(t => t._id !== item._id);
        this.setState({ selectedItems: items });
      }
    }
  };

  handelBatchActionClick({ key }) {
    switch (key) {
      case "edit":
        this.onEdit();
        break;
      case "delete":
        this.onDelete();
        break;
      case "createGroup":
        this.onGroup();
        break;
      default:
        return;
    }
  }

  render() {
    const { isLoading, unapprovedMembers } = this.props;
    const { columns, selectedItems } = this.state;
    const dataSource = this.getDataSource();

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="importMembers">Import Members</Menu.Item>
        <Menu.Item key="exportData">Export Data</Menu.Item>
      </Menu>
    );

    const batchActions = (
      <Menu onClick={e => this.handelBatchActionClick(e)}>
        <Menu.Item
          key="edit"
          disabled={
            selectedItems.length !== 1 || !hasUpdatePermission("members")
          }
        >
          Edit
        </Menu.Item>
        <Menu.Item key="delete" disabled={!hasDeletePermission("members")}>
          Delete
        </Menu.Item>
        <Menu.Item key="createGroup">Create Group</Menu.Item>
      </Menu>
    );

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <span style={{ fontWeight: 400, fontSize: 28 }}>Members</span>
          </div>

          <div style={{ flex: 1, textAlign: "right" }}>
            {selectedItems.length !== 0 && (
              <Dropdown
                overlay={batchActions}
                trigger={["click"]}
                style={{ marginLeft: 16 }}
              >
                <Button>
                  Batch Actions
                  <Icon type="down" />
                </Button>
              </Dropdown>
            )}
            {unapprovedMembers && unapprovedMembers.length > 0 && (
              <Badge count={unapprovedMembers.length}>
                <Button
                  type="primary"
                  onClick={this.onApproveMembers}
                  style={{ marginLeft: 16 }}
                >
                  Unapproved
                </Button>
              </Badge>
            )}
            {hasCreatePermission("members") && (
              <Dropdown.Button
                onClick={this.onMember}
                overlay={menu}
                style={{ marginLeft: 16 }}
              >
                <Icon type="plus" />
                New
              </Dropdown.Button>
            )}
          </div>
        </div>

        {columns && (
          <Table
            columns={columns}
            dataSource={dataSource}
            onClick={this.onViewMember}
            pagination
            selectable
            onSelectChange={this.onSelectChange}
            selectedItems={selectedItems}
            showSelectedCount
          />
        )}

        <Member
          visible={this.state.isMemberVisible}
          onClose={this.onCloseMember}
          onSubmitSuccess={this.onMemberSubmitSuccess}
          initialValues={this.state.initialMemberValues}
          // enableReinitialize
          newItem={this.newItem}
        />

        <ViewMember
          visible={this.state.isViewMemberVisible}
          onClose={this.onCloseViewMember}
          selectedMember={this.selectedMember}
          onUpdate={this.onEditMember}
          onDelete={this.onDeleteMember}
        />

        <Group
          visible={this.state.isGroupVisible}
          onClose={this.onCloseGroup}
          onSubmitSuccess={this.onGroupSubmitSuccess}
          initialValues={this.state.initialGroupValues}
          enableReinitialize
          newItem={this.newItem}
        />
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMembers,
  approvedMembersSelector,
  membersSchemaSelector,
  unapprovedMembersSelector,
  (isLoading, members, schema, unapprovedMembers) => ({
    isLoading,
    members,
    schema,
    unapprovedMembers
  })
);

const mapDispatchToProps = dispatch => {
  return {
    fetchMembers: () => dispatch(fetchMembers()),
    deleteManyMembers: members => dispatch(deleteManyMembers(members)),
    deleteMember: member => dispatch(deleteMember(member)),
    resetForm: () => dispatch(reset("member-form"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersPage);
