import React, { Component } from "react";
import { Button, Divider, Icon, Spin, Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { isLoadingMembers, membersSchemaSelector } from "../redux/selectors";
import moment from "moment";
import { ExportToCsv } from "export-to-csv";
import SendEmail from "../forms/sendEmail";
import Print from "../forms/print";
import { Table } from "../components/table";

import { loadingIcon } from "../utils/loadingIcon";

class GroupGeneral extends Component {
  constructor() {
    super();
    this.state = {
      columns: [],
      searchQuery: "",
      selectedItems: [],
      isSendEmailVisible: false,
      isPrintVisible: false,
      members: []
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const members =
      location.state && location.state.group && location.state.group.members;

    this.setState({ members });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.schema &&
      (prevProps.schema !== this.props.schema ||
        this.state.columns.length === 0)
    ) {
      let columns = this.props.schema.map(t => ({
        title: t.name,
        key: t.name,
        render:
          (t.type === "Date" && (t => moment(t).format("DD MMM YYYY"))) ||
          (t => t)
      }));
      this.setState({ columns });
    }
  }

  exportData = () => {
    let members = Object.assign([], this.state.selectedItems);
    const { columns } = this.state;

    // map data before exporting
    let data = members.map(t => {
      let { _id, createdAt, updatedAt, __v, ...rest } = t;
      for (let column of columns) {
        if (!rest[column.key]) rest[column.key] = "";
      }
      return rest;
    });

    const options = {
      filename: "members",
      showLabels: true,
      useKeysAsHeaders: true
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  getDataSource = () => {
    const { members } = this.state;
    return members;
  };

  onSendEmail = () => {
    let members = Object.assign([], this.state.selectedItems);
    let emails = members.map(t => t.Email);
    emails = emails.filter(t => t !== undefined && t !== "");
    this.setState({
      initialSendEmailValues: { to: emails },
      isSendEmailVisible: true
    });
  };

  onCloseSendEmail = () => {
    this.setState({ isSendEmailVisible: false });
  };

  onSendEmailSubmitSuccess = () => {
    this.setState({ isSendEmailVisible: false, selectedItems: [] });
  };

  onPrintToPdf = () => {
    let members = Object.assign([], this.state.selectedItems);
    this.setState({ initialPrintValues: members, isPrintVisible: true });
  };

  onClosePrint = () => {
    this.setState({ isPrintVisible: false });
  };

  onPrintSubmitSuccess = () => {
    this.setState({ isPrintVisible: false });
  };

  onExportToText = () => {
    let members = Object.assign([], this.state.selectedItems);
    let result = "";
    members.forEach(member => {
      result +=
        member["LM No"] +
        "\n" +
        member["Title"] +
        ". " +
        member["Print Name"] +
        " " +
        member["Surname"] +
        "\n" +
        member["Address 1"] +
        "\n" +
        member["Address 2"] +
        "\n" +
        member["City"] +
        "\n" +
        member["State"] +
        " " +
        member["Postal Code"] +
        "\n\n";
    });

    const element = document.createElement("a");
    const file = new Blob([result], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "output.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  onSelectChange = (checked, item) => {
    const { selectedItems } = this.state;
    if (checked) {
      let items = Object.assign([], selectedItems);
      if (item instanceof Array) {
        items = item;
      } else {
        items.push(item);
      }
      this.setState({ selectedItems: items });
    } else {
      if (item instanceof Array) {
        this.setState({ selectedItems: [] });
      } else {
        let items = selectedItems.filter(t => t._id !== item._id);
        this.setState({ selectedItems: items });
      }
    }
  };

  handelActionClick({ key }) {
    switch (key) {
      case "sendEmail":
        this.onSendEmail();
        break;
      case "exportToCsv":
        this.exportData();
        break;
      case "printToPdf":
        this.onPrintToPdf();
        break;
      case "exportToText":
        this.onExportToText();
        break;
      default:
        return;
    }
  }

  render() {
    const dataSource = this.getDataSource();
    const { isLoading, location } = this.props;
    const { columns, selectedItems } = this.state;

    const groupName =
      location.state && location.state.group && location.state.group.name;

    const Actions = (
      <Menu onClick={e => this.handelActionClick(e)}>
        <Menu.Item key="sendEmail">Send Email</Menu.Item>
        <Menu.Item key="exportToCsv">Export To CSV</Menu.Item>
        <Menu.Item key="printToPdf">Print To PDF</Menu.Item>
        <Menu.Item key="exportToText">Export To Text</Menu.Item>
      </Menu>
    );

    return (
      <Spin spinning={isLoading} indicator={loadingIcon}>
        <div style={{ display: "flex", marginBottom: 16 }}>
          <div style={{ flex: 1, textAlign: "left" }}>
            <Button
              icon="arrow-left"
              onClick={() => this.props.history.goBack()}
            />
            <Divider type="vertical" />
            <span style={{ fontWeight: 400, fontSize: 20 }}>Group</span>
            <Divider type="vertical" />
            <span style={{ fontWeight: 600, fontSize: 20 }}>{groupName}</span>
          </div>

          <div style={{ flex: 1, textAlign: "right" }}>
            <Dropdown
              overlay={Actions}
              trigger={["click"]}
              disabled={selectedItems.length === 0}
            >
              <Button style={{ marginLeft: 8 }}>
                Actions
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          selectable
          onSelectChange={this.onSelectChange}
          selectedItems={selectedItems}
          pagination
        />

        <SendEmail
          visible={this.state.isSendEmailVisible}
          onClose={this.onCloseSendEmail}
          onSubmitSuccess={this.onSendEmailSubmitSuccess}
          initialValues={this.state.initialSendEmailValues}
          enableReinitialize
        />

        <Print
          visible={this.state.isPrintVisible}
          onClose={this.onClosePrint}
          onSubmitSuccess={this.onPrintSubmitSuccess}
          initialValues={this.state.initialPrintValues}
          enableReinitialize
        />
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  isLoadingMembers,
  membersSchemaSelector,
  (isLoading, schema) => ({
    isLoading,
    schema
  })
);

export default connect(mapStateToProps)(GroupGeneral);
