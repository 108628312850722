import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

export const renderDatePicker = props => {
  const {
    meta: { touched, error, warning }
  } = props;
  return (
    <span>
      <DatePicker
        value={(props.input.value && moment(props.input.value)) || undefined}
        onChange={(d, s) => props.input.onChange(d)}
        {...props}
      />
      {touched &&
        ((error && <div style={{ color: "#cf1322" }}>{error}</div>) ||
          (warning && <div style={{ color: "#ffa940" }}>{warning}</div>))}
    </span>
  );
};
