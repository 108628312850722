import React from "react";
import { Select } from "antd";

const Option = Select.Option;

export const renderSelectSearch = props => {
  const {
    meta: { touched, error, warning }
  } = props;
  return (
    <span>
      <Select
        showSearch
        value={props.input.value}
        onChange={e => props.input.onChange(e)}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children &&
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        maxTagCount={8}
        allowClear
        {...props}
      >
        {props.options.map((item, index) => (
          <Option key={index} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
      {touched &&
        ((error && <div style={{ color: "#cf1322" }}>{error}</div>) ||
          (warning && <div style={{ color: "#ffa940" }}>{warning}</div>))}
    </span>
  );
};
