import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { sendRequest } from "../../api";
import * as types from "../actions/types";
import { notifySuccess, notifyError } from "../../utils/notification";

function* fetchReports() {
  try {
    const reports = yield call(sendRequest, "reports", "GET");
    yield put({ type: types.FETCH_REPORTS_SUCCESS, payload: reports.data });
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* createReport({ payload }) {
  try {
    const response = yield call(sendRequest, "reports", "POST", payload);
    yield put({ type: types.FETCH_REPORTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* updateReport({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `reports/${payload._id}`,
      "PUT",
      payload
    );
    yield put({ type: types.FETCH_REPORTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

function* deleteReport({ payload }) {
  try {
    const response = yield call(
      sendRequest,
      `reports/${payload._id}`,
      "DELETE",
      payload
    );
    yield put({ type: types.FETCH_REPORTS });
    yield notifySuccess(response.data);
  } catch (error) {
    yield notifyError(error.response.data);
  }
}

export function* reportsSagaWatcher() {
  yield takeLatest(types.FETCH_REPORTS, fetchReports);
  yield takeEvery(types.CREATE_REPORT, createReport);
  yield takeEvery(types.UPDATE_REPORT, updateReport);
  yield takeEvery(types.DELETE_REPORT, deleteReport);
}
